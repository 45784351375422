import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  GenericState,
  getObj,
  getBaseObjList,
  PoiWithFoodMenu,
} from '@skczu/czu-frontend-library';
import {clearObjectPreview, setActiveObjectPreview} from '../DrawerSlice';
import {CustomUtils} from '../../../components/shared/utils/CustomUtils';
import {PayloadAction} from '@reduxjs/toolkit';
import config from '../../../config';
import {
  BasePoiWeb,
  Poi,
  PoiApi,
  WebPoiFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {
  foodMenuActions,
  getRestaurantDailyMenu,
  getRestaurantMenu,
} from './FoodMenuSlice';
import moment from 'moment/moment';

export interface PoiState extends GenericState<BasePoiWeb, PoiWithFoodMenu> {
  filterCategory?: PoiCategory;
}

const initialState: PoiState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const poiSlice = createGenericSlice({
  name: 'poi',
  initialState,
})({
  setSelectedFilterCategory: (
    state: PoiState,
    {payload}: PayloadAction<PoiCategory | undefined>
  ) => {
    state.filterCategory = payload;
  },
});

export const getBasePoiList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<BasePoiWeb, WebPoiFilterResponse>(
        getState().poiList.baseObjSearch,
        getState().poiList.baseObjList,
        () =>
          new PoiApi(undefined, config.cmsRestUrl).poiWebFilterGet(
            keyword,
            getState().poiList.filterCategory?.id,
            getState().poiList.baseObjSearch.limit,
            getState().poiList.baseObjSearch.offset
          ),
        (loading) => dispatch(poiActions.setObjLoadingList(loading)),
        (fail) => dispatch(poiActions.setObjFailed({message: fail})),
        (offset) => dispatch(poiActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(poiActions.setBaseObjList(newDataList ? newDataList : []));
          } else {
            dispatch(poiActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(poiActions.hasMore(hasMore))
      )
    );
  };

export const getPoi =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Poi>(
        id,
        (poiId) =>
          new PoiApi(undefined, config.cmsRestUrl).poiIdGet(poiId as string),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(poiActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(poiActions.setObj(obj));
        }
      )
    );
  };

export const getPoiForMobilePreview =
  (id: string, scrollTo: (id: string) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(clearObjectPreview());
    dispatch(foodMenuActions.setRestaurantMenus());
    dispatch(foodMenuActions.setRestaurantWeekMenu());
    dispatch(foodMenuActions.setRestaurantDailyMenu());
    dispatch(foodMenuActions.setRestaurantDetail());
    dispatch(
      getObj<Poi>(
        id,
        (poiId) =>
          new PoiApi(undefined, config.cmsRestUrl).poiIdGet(poiId as string),
        (loading) => dispatch(poiActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(poiActions.setObjFailed({message: fail})),
        (obj) => {
          const setPoiToList = (poi: Poi) => {
            if (!getState().poiList.baseObjList.some((poi) => poi.id === id)) {
              !getState().poiList.baseObjList &&
                dispatch(
                  getBasePoiList(
                    true,
                    getState().poiList.baseObjSearch.searchQuery
                      ? getState().poiList.baseObjSearch.searchQuery
                      : undefined
                  )
                );
              dispatch(
                poiActions.addToBaseObjList([
                  CustomUtils.convertPoiToBasePoi(poi),
                ])
              );
            }
            scrollTo(id);
          };

          if (obj.foodPointId) {
            dispatch(
              getRestaurantMenu(obj.foodPointId, (food) => {
                dispatch(
                  setActiveObjectPreview({
                    objectPreview: {
                      objectMobileScene: 'poi',
                      objectPreviewData: {
                        ...obj,
                        foodMenuData: {
                          restaurantMenu: food ? food : undefined,
                          restaurantDetail: obj.restaurant
                            ? obj.restaurant
                            : undefined,
                          selectedWeekDates: {
                            fromDay: moment()
                              .startOf('week')
                              .format('YYYY-MM-DD'),
                            toDay: moment().endOf('week').format('YYYY-MM-DD'),
                          },
                        },
                      },
                    },
                    objectPreviewView: {
                      openObjectPreview: true,
                    },
                  })
                );
                setPoiToList(obj);
                obj?.foodPointId &&
                  dispatch(
                    getRestaurantDailyMenu(
                      obj.foodPointId,
                      moment().format('YYYY-MM-DD'),
                      true
                    )
                  );
              })
            );
          } else {
            dispatch(
              setActiveObjectPreview({
                objectPreview: {
                  objectMobileScene: 'poi',
                  objectPreviewData: obj,
                },
                objectPreviewView: {
                  openObjectPreview: true,
                },
              })
            );
            setPoiToList(obj);
          }
        }
      )
    );
  };

export const poiActions = poiSlice.actions;

export const poiSelector = (state: RootState): typeof state.poiList =>
  state.poiList;

export default poiSlice.reducer;
