import React from 'react';
import {Avatar, Box, Typography} from '@mui/material';
import {StylesRow} from './StylesRow';
import makeStyles from '@mui/styles/makeStyles';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {ListItemData, ListItemRow} from '@skczu/czu-frontend-library';
import {BaseRouteWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {PoiRef} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route/api';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  routePanel: {
    flex: '1 1 auto',
    alignSelf: 'flex-start',
    width: '60%',
    minHeight: 101,
  },
  routePanelText: {
    paddingBottom: 26,
    paddingLeft: 10,
    alignSelf: 'flex-start',
  },
  routePoiText: {
    color: theme.palette.grey[500],
  },
}));

const styles = {
  imagesBackgroundContainer: {
    position: 'relative',
    backgroundColor: 'secondary.light',
    height: 100,
    width: 100,
    minWidth: 100,
    overflow: 'hidden',
    marginTop: '2px',
    marginBottom: '2px',
  },
  imagesBackgroundFirst: {
    zIndex: 3,
    backgroundColor: 'primary.light',
    position: 'absolute',
    bottom: -10,
    right: -78,
    width: 120,
    height: 120,
  },
  imagesBackgroundSecond: {
    zIndex: 2,
    backgroundColor: 'primary.main',
    position: 'absolute',
    bottom: -55,
    left: -10,
    width: 110,
    height: 110,
  },
  imagesBackgroundThird: {
    zIndex: 1,
    backgroundColor: 'primary.dark',
    position: 'absolute',
    left: -15,
    top: -25,
    width: 135,
    height: 135,
  },
};
interface RouteImageProps {
  poi?: PoiRef;
  sx?: SxProps<Theme>;
}
const RouteImage = ({poi, sx}: RouteImageProps): JSX.Element | null => {
  return poi && poi.image?.thumbnailUrl ? (
    <Avatar alt={poi.image.fileName} src={poi.image.thumbnailUrl} sx={sx} />
  ) : null;
};

export interface RouteMenuRowProps {
  listItem: ListItemRow;
  baseObj: BaseRouteWeb;
  handleItemClick?: (id: string) => void;
}

export const RouteMenuRow = ({
  listItem,
  baseObj,
  handleItemClick,
}: RouteMenuRowProps): JSX.Element => {
  const classes = useStyles();

  const getPois = (route: ListItemData): PoiRef[] => {
    return (route as BaseRouteWeb).pois as PoiRef[];
  };

  return (
    <Box
      sx={{
        ...(listItem.selectedItem
          ? {...StylesRow.listItem, ...StylesRow.listItemSelected}
          : StylesRow.listItem),
      }}
      key={listItem.id}
      onClick={() => handleItemClick && handleItemClick(listItem.id)}>
      <Box className={classes.routePanel}>
        <Box className={classes.routePanelText}>
          <Typography variant={'subtitle1'} sx={StylesRow.title}>
            {listItem.itemRowName}
          </Typography>
          <Typography variant={'body2'} className={classes.routePoiText}>
            {listItem?.data &&
              getPois(listItem.data) &&
              getPois(listItem.data)
                .map(
                  (poi) =>
                    ' ' + CustomUtils.getContentByLanguage(poi.name, poi.nameEn)
                )
                .toString()}
          </Typography>
        </Box>
      </Box>
      {baseObj.pois && (
        <Box sx={styles.imagesBackgroundContainer}>
          {baseObj.pois[0] && (
            <RouteImage
              poi={baseObj.pois[0]}
              sx={styles.imagesBackgroundThird}
            />
          )}
          {baseObj.pois[1] && (
            <RouteImage
              poi={baseObj.pois[1]}
              sx={styles.imagesBackgroundSecond}
            />
          )}
          {baseObj.pois[2] && (
            <RouteImage
              poi={baseObj.pois[2]}
              sx={styles.imagesBackgroundFirst}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
