import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MapButton} from './MapButton';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {selectMapType, setMapType} from '../../../../redux/reducers/MapSlice';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
  },
}));

export const MenuBottom = (): JSX.Element => {
  const mapType = useAppSelector(selectMapType);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const navigateTo = useCallback((path) => history.push(path), [history]);
  return (
    <Box className={classes.buttonContainer}>
      <MapButton
        imageSource={
          mapType === '2D'
            ? '/assets/icon/2D.png'
            : '/assets/icon/2DnotSelected.png'
        }
        selected={mapType === '2D'}
        buttonName={'2D'}
        onClick={() => dispatch(setMapType('2D'))}
      />
      <MapButton
        imageSource={
          mapType === '3D'
            ? '/assets/icon/3D.png'
            : '/assets/icon/3DnotSelected.png'
        }
        selected={mapType === '3D'}
        buttonName={'3D'}
        onClick={() => dispatch(setMapType('3D'))}
      />
      <MapButton
        imageSource={'/assets/icon/panorama.png'}
        buttonName={'VR'}
        onClick={() => navigateTo('/map/panorama')}
      />
    </Box>
  );
};
