import {Box, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {BasicDialog} from '@skczu/czu-react-components';
import {useTranslation} from 'react-i18next';
import {CustomUtils} from '../../../../shared/utils/CustomUtils';
import {Update} from '@mui/icons-material';
import {MenuItemRow} from './MenuItemRow';
import {useAppDispatch, useAppSelector} from '../../../../../hooks/hooks';
import {
  getWishlist,
  wishlistSelector,
} from '../../../../../redux/reducers/data-reducers/WishlistSlice';

export interface WishlistDialogProps {
  open?: boolean;
}

export const WishlistMenuItem = ({open}: WishlistDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {wishlist} = useAppSelector(wishlistSelector);
  const [wishlistDialog, setWishlistDialog] = useState(false);

  useEffect(() => {
    wishlistDialog && dispatch(getWishlist());
  }, [dispatch, wishlistDialog]);

  return (
    <>
      <MenuItemRow
        name={t('leftMenu.Upcoming changes')}
        onClick={() => setWishlistDialog(true)}
        open={open}
        icon={<Update />}
      />

      <BasicDialog
        open={wishlistDialog}
        title={t('leftMenu.Upcoming changes')}
        onCloseDialog={() => setWishlistDialog(false)}>
        <Box m={3} paddingBottom={10}>
          {wishlist?.text && wishlist?.visible ? (
            <div
              dangerouslySetInnerHTML={{
                __html: CustomUtils.getContentByLanguage(
                  wishlist?.text,
                  wishlist?.textEn
                ),
              }}
            />
          ) : (
            <Box>
              <Typography style={{textAlign: 'center', color: '#8a8a8a'}}>
                {t(
                  'leftMenu.Information about planned changes will be displayed here'
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </BasicDialog>
    </>
  );
};
