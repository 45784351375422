import {
  createGenericSlice,
  GenericState,
  getBaseObjList,
  InfiniteSearch,
} from '@skczu/czu-frontend-library';
import {AppThunk, RootState} from '../../../store';
import {setLoading} from '../LoadingSlice';
import config from '../../../config';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {
  PoiCategoryApi,
  PoiCategoryFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {PayloadAction} from '@reduxjs/toolkit';
import {setFilterCategoryId} from '../MapSlice';

export interface PoiCategoriesState
  extends GenericState<PoiCategory, PoiCategory> {
  selectedEventsCategories: PoiCategory[];
}
const initialState: PoiCategoriesState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,

    offset: 0,
  },
  error: {message: 'An Error occurred'},
  filterDataList: [],
  selectedEventsCategories: [],
  filterObjSearch: {
    limit: 10,
    offset: 0,
  },
};

const poiCategoriesSlice = createGenericSlice({
  name: 'poiCategories',
  initialState,
})({
  setEventCategories: (state, {payload}: PayloadAction<PoiCategory[]>) => {
    state.selectedEventsCategories = payload;
  },
});

export const getPoiCategoryList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(poiCategoryActions.setObjLoadingList(true));
    const categoriesList = await new PoiCategoryApi(
      undefined,
      config.cmsRestUrl
    ).poiCategoryGet();
    if (categoriesList.data) {
      dispatch(poiCategoryActions.addToBaseObjList(categoriesList.data));
    }
  } catch (error) {
    // console.log(JSON.stringify(error));
  } finally {
    dispatch(poiCategoryActions.setObjLoadingList(false));
  }
};

export const getEventCategoriesList =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const selectedEventIds = getState().map.mapFilter.selectedGroups.map(
        (selectedEvent) => selectedEvent.id as string
      );
      const categoriesList = await new PoiCategoryApi(
        undefined,
        config?.cmsRestUrl
      ).eventCategoryGet(selectedEventIds, selectedEventIds.length === 0);
      const selectedCategoryId = getState().map.mapFilter.selectedCategoryId;
      categoriesList?.data.categories &&
        dispatch(
          poiCategoryActions.setEventCategories(categoriesList.data.categories)
        );
      if (selectedCategoryId) {
        const foundCategory = categoriesList.data.categories?.find(
          (cat) => cat.id === selectedCategoryId
        );
        !foundCategory && dispatch(setFilterCategoryId(''));
      }
    } catch (error) {
      // console.log(JSON.stringify(error));
    }
  };

export const getFilterPoiCategoryList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<PoiCategory, PoiCategoryFilterResponse>(
        getState().poiCategories.filterObjSearch as InfiniteSearch,
        getState().poiCategories.filterDataList as PoiCategory[],
        () =>
          new PoiCategoryApi(undefined, config.cmsRestUrl).poiCategoryFilterGet(
            keyword,
            99999,
            0
          ),
        (loading) => dispatch(setLoading(loading)),
        (fail) => dispatch(poiCategoryActions.setObjFailed({message: fail})),
        () => void 0,
        (newDataList) =>
          dispatch(poiCategoryActions.setFilterDataList(newDataList)),
        keyword
      )
    );
  };

export const poiCategoryActions = poiCategoriesSlice.actions;

export const poiCategoriesSelector = (
  state: RootState
): typeof state.poiCategories => state.poiCategories;

export default poiCategoriesSlice.reducer;
