import React from 'react';
import {Box, Typography} from '@mui/material';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

export const FooterHeight = isMobile ? '40vw' : '8vw';

const styles = {
  footerText: {
    fontSize: isMobile ? '3.5vw' : '0.8vw',
  },
  footerSection: {
    paddingTop: isMobile ? '2vw' : '0.5vw',
    display: 'inherit',
    flexDirection: 'inherit',
    alignItems: 'inherit',
    maxWidth: '96%',
  },
};

export const SignpostFooter = (): JSX.Element => {
  const {t} = useTranslation();

  return (
    <footer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#efefef',
        paddingTop: isMobile ? '4vw' : '1vw',
        paddingBottom: isMobile ? '4vw' : '1vw',
        width: '100%',
        height: FooterHeight,
      }}>
      <Box sx={styles.footerSection}>
        <Typography sx={styles.footerText}>
          {t(
            'signPost.The application is operated by the Department of Information and Communication Technologies, CZU in Prague'
          )}
        </Typography>
        <Box sx={styles.footerSection}>
          <Typography sx={styles.footerText}>
            {t(
              'signPost.Materials placed on this website may be published only with the CZU consent'
            )}
          </Typography>
        </Box>
        <Typography sx={styles.footerText}>
          {t('signPost.Czech University of Life Sciences in Prague')}
        </Typography>
        <Typography sx={styles.footerText}>
          {t('signPost.All rights reserved')}
        </Typography>
      </Box>
    </footer>
  );
};
