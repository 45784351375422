import React from 'react';
import {Box} from '@mui/material';
import {PlatformButton} from './PlatformButton';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import config from '../../../../config';

const styles = {
  platformButtons: {
    marginTop: isMobile ? '8vw' : '4vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  googlePlayLogo: {
    width: isMobile ? '14vw' : '3.5vw',
    height: isMobile ? '14vw' : '3.5vw',
  },
  webLogo: {
    width: isMobile ? '13vw' : '3.4vw',
    height: isMobile ? '13vw' : '3.4vw',
    marginRight: '0.6vw',
  },
  appleLogo: {
    width: isMobile ? '13vw' : '3.5vw',
    height: isMobile ? '13vw' : '3.5vw',
    marginRight: isMobile ? '2vw' : '0.6vw',
  },
};

export const PlatformButtons = (): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Box sx={styles.platformButtons}>
      <PlatformButton
        text={t('signPost.GET IT ON')}
        name={'Google Play'}
        icon={
          <Box
            component={'img'}
            alt={'GooglePlay'}
            src={'/assets/logo/GooglePlayLogo.png'}
            sx={styles.googlePlayLogo}
          />
        }
        onClick={() => (window.location.href = config?.dynamicLinkUrl)}
      />
      <PlatformButton
        text={t('signPost.GET IT ON')}
        name={'App Store'}
        icon={
          <Box
            component={'img'}
            alt={'AppStore'}
            src={'/assets/logo/AppleLogo.png'}
            sx={styles.appleLogo}
          />
        }
        onClick={() => (window.location.href = config?.dynamicLinkUrl)}
      />
      {!isMobile && (
        <PlatformButton
          text={'PC & MAC'}
          name={'Web app'}
          onClick={() => (window.location.href = config?.dynamicLinkUrl)}
          icon={
            <Box
              component={'img'}
              alt={'Web'}
              src={'/assets/logo/WebLogo.png'}
              sx={styles.webLogo}
            />
          }
        />
      )}
    </Box>
  );
};
