import {Box, Typography} from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BasicDialog} from '@skczu/czu-react-components';
import {useTranslation} from 'react-i18next';
import {ButtonAdd} from '../../buttons/ButtonAdd';
import {Check} from '@mui/icons-material';

export interface SubmittedFeedbackDialogProps {
  showDialog: boolean;
  onDialogDismiss: () => void;
}

export const SubmittedFeedbackDialog = ({
  showDialog,
  onDialogDismiss,
}: SubmittedFeedbackDialogProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <BasicDialog open={showDialog} onCloseDialog={onDialogDismiss}>
      <>
        <Box className={classes.dialogContainer}>
          <Box className={classes.dialogIcon}>
            <Check color={'primary'} className={classes.icon} />
          </Box>
          <Typography className={classes.title}>
            {t('feedback.Thank you for your feedback!')}
          </Typography>
          <Typography className={classes.text}>
            {t('feedback.Your submission has been sent')}
          </Typography>
        </Box>
        <ButtonAdd
          name={t('feedback.Continue')}
          icon={<></>}
          onClick={onDialogDismiss}
        />
      </>
    </BasicDialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 18,
    marginBottom: 30,
  },
  icon: {
    width: 100,
    height: 100,
  },
  dialogIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    width: 100,
    height: 100,
    borderRadius: 100,
    border: '6px solid rgba(115,190,70,0.6)',
  },
  description: {
    width: '100%',
    marginBottom: 30,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 22,
    marginTop: 18,
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 15,
    marginBottom: 4,
  },
  logoInfo: {
    width: 50,
    height: 50,
  },
}));
