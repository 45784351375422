import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Language} from '@skczu/czu-frontend-library';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  buttonContainerShown: {
    display: 'flex',
    alignItems: 'flex-end',
    visibility: 'visible',
    marginTop: isMobile ? '2vw' : '0.5vw',
    marginRight: isMobile ? '2.5vw' : '0.7vw',
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    border: '3px solid transparent',
    fontSize: isMobile ? '4vw' : '1.1vw',
    opacity: 0.5,
  },
  languageActive: {
    fontSize: isMobile ? '5vw' : '1.3vw',
    opacity: 1,
  },
}));

export interface SignPostLanguageProps {
  onChange: (language: Language) => void;
  language: Language;
}

export const SignPostLanguage = ({
  onChange,
  language,
}: SignPostLanguageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.buttonContainerShown}>
      <Box
        className={
          classes.button +
          ' ' +
          (language === 'cs' ? classes.languageActive : ' ')
        }
        onClick={() => onChange('cs')}>
        CZ
      </Box>
      <Box
        className={
          classes.button +
          ' ' +
          (language === 'en' ? classes.languageActive : ' ')
        }
        onClick={() => onChange('en')}>
        EN
      </Box>
    </Box>
  );
};
