import React from 'react';
import {Box, Typography} from '@mui/material';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {PlatformButtons} from './PlatformButtons';
import {Language} from '@skczu/czu-frontend-library';
import i18n from 'i18next';
import {SignPostLanguage} from './SignpostLanguage';

const styles = {
  headerContentContainer: {
    zIndex: 2,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  languageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    width: isMobile ? '24vw' : '6vw',
    height: isMobile ? '12vw' : '3vw',
  },
  nameText: {
    fontSize: isMobile ? '6vw' : '2vw',
    marginBottom: isMobile ? '3vw' : '2vw',
  },
  headerSubText: {fontSize: isMobile ? '5vw' : '2vw'},
  headerText: {
    fontSize: isMobile ? '8vw' : '4.5vw',
    fontWeight: 'bold',
    lineHeight: isMobile ? '10vw' : '6vw',
    marginBottom: isMobile ? '3vw' : '1vw',
  },
  text: {
    color: 'secondary.main',
    maxWidth: '90%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const SignpostHeader = (): JSX.Element => {
  const {t} = useTranslation();

  const handleChangeLanguage = async (language: Language) => {
    await i18n.changeLanguage(language);
  };

  return (
    <Box sx={styles.headerContentContainer}>
      <Box sx={styles.languageContainer}>
        <SignPostLanguage
          onChange={handleChangeLanguage}
          language={i18n.language as Language}
        />
      </Box>
      <Box
        component={'img'}
        alt={'CZULogoPNG'}
        src={'/assets/logo/CZULogoPNG.png'}
        sx={styles.logo}
      />
      <Box sx={styles.textContainer}>
        <Typography sx={{...styles.text, ...styles.nameText}}>
          My ČZU
        </Typography>
        <Typography sx={{...styles.text, ...styles.headerText}}>
          {t('signPost.All you need in one place')}
        </Typography>
        <Box />
        <Typography sx={{...styles.text, ...styles.headerSubText}}>
          {t('signPost.Study, visit and explore with My ČZU app')}
        </Typography>
      </Box>
      <PlatformButtons />
    </Box>
  );
};
