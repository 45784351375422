import React, {MouseEventHandler} from 'react';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

export interface Props {
  name: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  icon?: React.ReactNode;
  open?: boolean;
}

export const MenuItemRow = ({
  name,
  onClick,
  open,
  icon,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.container]: true,
        [classes.opened]: open,
      })}
      onClick={onClick}>
      <Box
        style={{
          display: 'flex',
          flex: 1,
          paddingLeft: 10,
        }}>
        <Box
          className={clsx({
            [classes.icon]: open,
            [classes.iconClose]: !open,
          })}>
          {icon}
        </Box>
        <Typography
          sx={{
            opacity: open ? 1 : 0,
            fontSize: 11,
            marginTop: '3px',
            marginBottom: '4px',
            alignSelf: 'center',
            color: 'secondary.main',
          }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  opened: {
    borderTop: '1px solid #404040',
    paddingTop: 4,
    paddingBottom: 4,
  },
  text: {
    transition: theme.transitions.create(
      ['opacity', 'visibility', 'fontSize', 'width'],
      {
        duration: 800,
      }
    ),
    visibility: 'visible',

    color: theme.palette.secondary.main,
  },
  textClose: {
    transition: theme.transitions.create(
      ['opacity', 'visibility', 'fontSize', 'width'],
      {
        duration: 800,
      }
    ),
    visibility: 'hidden',
    opacity: 0,
    fontSize: 0,
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.secondary.main,
  },
  icon: {
    transition: theme.transitions.create(
      ['opacity', 'visibility', 'fontSize'],
      {
        duration: 800,
      }
    ),
    visibility: 'visible',
    opacity: 1,
    fontSize: 17,
    marginTop: 3,
    marginBottom: 4,
    paddingRight: 8,
    color: theme.palette.secondary.main,
  },
  iconClose: {
    transition: theme.transitions.create(
      ['opacity', 'visibility', 'fontSize'],
      {
        duration: 200,
      }
    ),
    visibility: 'hidden',
    opacity: 0,
    fontSize: 0,
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.secondary.main,
  },
}));
