import React, {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  selectMapFilter,
  setFilter,
  setFilterCategoryId,
  setFilterSelectedGroup,
} from '../../../../redux/reducers/MapSlice';
import {useTranslation} from 'react-i18next';
import {
  getEventCategoriesList,
  poiCategoriesSelector,
} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {
  groupActions,
  groupsSelector,
} from '../../../../redux/reducers/data-reducers/GroupsSlice';

import {
  FilterPanel,
  CategoryListItem,
  FilterList,
  MapFilterContainer,
  SwitchListItem,
} from '@skczu/czu-react-components';
import {CustomUtils} from '../../../shared/utils/CustomUtils';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import i18n from 'i18next';

export const MapFilter = (): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {filterDataList} = useAppSelector(groupsSelector);
  const {selectedEventsCategories} = useAppSelector(poiCategoriesSelector);
  const {mapFilter, isFilter} = useAppSelector(selectMapFilter);
  const [categoryQuery, setCategoryQuery] = React.useState('');
  const [eventQuery, setEventQuery] = React.useState('');

  const handleCategorySelection = useCallback(
    (category: PoiCategory) => {
      if (category.id) {
        dispatch(setFilterCategoryId(category.id));
      }
    },
    [dispatch]
  );
  const language = i18n.language;
  const renderGroupList = useMemo(() => {
    const filteredOptions =
      filterDataList &&
      CustomUtils.getFilteredOptionsByName(filterDataList, eventQuery);
    return (
      <FilterList>
        {!!t &&
          filteredOptions &&
          language &&
          filteredOptions.map(
            (group, index) =>
              group.name && (
                <SwitchListItem
                  key={group.id + ':_filer_' + index}
                  item={CustomUtils.getObjByLanguage(group)}
                  onSelectItem={() => {
                    group.name && dispatch(setFilterSelectedGroup(group));
                    dispatch(groupActions.changeActivatedEvent(group));
                    dispatch(getEventCategoriesList());
                  }}
                  checked={
                    mapFilter.selectedGroups
                      .map((selectedGroup) => selectedGroup.id)
                      .indexOf(group.id) > -1
                  }
                />
              )
          )}
      </FilterList>
    );
  }, [
    dispatch,
    eventQuery,
    filterDataList,
    mapFilter.selectedGroups,
    language,
    t,
  ]);

  const renderCategoryList = useMemo(() => {
    const filteredOptions = CustomUtils.getFilteredOptionsByName(
      selectedEventsCategories,
      categoryQuery
    );
    return (
      <FilterList>
        {!!t &&
          filteredOptions &&
          language &&
          filteredOptions.map((category, index) => (
            <CategoryListItem
              key={category.id + ':_filer_' + index}
              category={CustomUtils.getObjByLanguage(category)}
              onSelectCategory={handleCategorySelection}
              checked={category.id === mapFilter.selectedCategoryId}
            />
          ))}
      </FilterList>
    );
  }, [
    selectedEventsCategories,
    categoryQuery,
    language,
    handleCategorySelection,
    mapFilter.selectedCategoryId,
    t,
  ]);
  return (
    <MapFilterContainer
      title={t('filter.Filter')}
      open={!!isFilter}
      onChangeOpen={(open) => dispatch(setFilter(open))}>
      {selectedEventsCategories && (
        <FilterPanel
          key={'filter-poi-categories'}
          filterPanelHeader={t('filter.Categories')}
          searchValue={{searchQuery: categoryQuery, limit: 99999, offset: 0}}
          panelList={() => renderCategoryList}
          onChangeValue={({value}) => setCategoryQuery(value)}
          // onSearchClick={categoryFilter.handleSearch}
          searchPlaceholder={t('leftMenu.search')}
        />
      )}
      {filterDataList && (
        <FilterPanel
          key={'filter-groups'}
          filterPanelHeader={t('filter.Groups')}
          searchValue={{searchQuery: eventQuery, limit: 99999, offset: 0}}
          panelList={() => renderGroupList}
          onChangeValue={({value}) => setEventQuery(value)}
          // onSearchClick={groupFilter.handleSearch}
          searchPlaceholder={t('leftMenu.search')}
        />
      )}
    </MapFilterContainer>
  );
};
