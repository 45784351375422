import i18n from 'i18next';
import {LocalizedParams} from '@skczu/czu-frontend-library';
import {DamFile} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/base';
import {CategoryIcon} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {
  Poi,
  PoiRef,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';

export const CustomUtils = {
  createPhotoFromObject3dReaderResult: (
    file: File,
    readerResult: string | ArrayBuffer | null
  ): DamFile => {
    const result = (readerResult as string).replace(
      'data:application/octet-stream;base64,',
      ''
    );
    return {
      data: result,
      fileName: file.name,
    } as DamFile;
  },
  capitalizeFirstLetter: (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  },
  convertPoiCategoryIconToPhoto: (icon: CategoryIcon): DamFile => {
    return {
      id: icon.id,
      data: icon.mobileUrl,
      fileName: icon.name,
    } as DamFile;
  },
  convertPoiToBasePoi: (poi: Poi): PoiRef => {
    return {
      id: poi.id,
      name: poi.name,
      nameEn: poi.nameEn,
      image: poi.image,
      category: poi.category?.id,
      coordinates: poi.coordinates,
    };
  },
  convertPoiListToBasePoiList: (poiList: Poi[]): PoiRef[] => {
    return poiList.map((poi) => CustomUtils.convertPoiToBasePoi(poi));
  },
  convertBasePoiListToPoiList: (basePoiList: PoiRef[]): Poi[] => {
    return basePoiList.map((basePoi) => {
      return {
        id: basePoi.id,
        name: basePoi.name,
        nameEn: basePoi.nameEn,
        image: basePoi.image,
        category: {
          id: basePoi.category,
        },
        coordinates: basePoi.coordinates,
      };
    });
  },
  hexToRGBA: (
    hex: string
  ): {red: number; green: number; blue: number} | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          red: parseInt(result[1], 16),
          green: parseInt(result[2], 16),
          blue: parseInt(result[3], 16),
        }
      : null;
  },
  getContentByLanguage: (czText?: string, enText?: string): string => {
    return (
      i18n.language === 'en'
        ? !enText ||
          enText.trim() === ('<p></p>' as string).trim() ||
          enText.trim() === ('<p>&nbsp;</p>' as string).trim()
          ? czText
          : enText
        : czText
    ) as string;
  },
  getObjByLanguage: <T extends LocalizedParams>(obj: T): T => {
    return {
      ...obj,
      name: CustomUtils.getContentByLanguage(obj?.name, obj?.nameEn),
      body: CustomUtils.getContentByLanguage(obj?.body, obj?.bodyEn),
    };
  },
  getFilteredOptionsByName: <T extends {name?: string; nameEn?: string}>(
    list: T[],
    query: string
  ): T[] => {
    return (
      list &&
      list.filter((option) => {
        return (
          !option ||
          (option &&
            (option.name
              ?.toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1 ||
              option.nameEn
                ?.toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) !== -1))
        );
      })
    );
  },
};
