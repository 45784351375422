import React, {ReactNode} from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AppFeature} from './AppImageInfo';
import {isMobile} from 'react-device-detect';
import i18n from 'i18next';

const useStyles = makeStyles(() => ({
  appFeatureImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  featureList: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isMobile ? 0 : '1vw',
    marginTop: isMobile ? '7vw' : '2vw',
  },
  appImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: isMobile ? 0 : '6vw',
    marginRight: isMobile ? 0 : '2vw',
    marginBottom: isMobile ? '1vw' : '8vw',
    position: 'relative',
  },
  appInfoImage: {
    position: 'absolute',
    top: isMobile ? '5vw' : '1.5vw',
    width: isMobile ? '50vw' : '19vw',
    height: isMobile ? '110vw' : '41vw',
    zIndex: 1,
  },
  appInfoImage2: {
    width: isMobile ? '60vw' : '22vw',
    height: isMobile ? '120vw' : '44vw',
    zIndex: -1,
  },
}));
export interface AppImageMenuProps {
  features: ReactNode;
  selectedFeature: AppFeature;
}
export const AppImageMenu = ({
  features,
  selectedFeature,
}: AppImageMenuProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.appFeatureImageContainer}>
      <Box className={classes.featureList}>{features}</Box>
      <Box className={classes.appImageContainer}>
        <img
          alt={selectedFeature.key}
          src={
            i18n.language === 'en'
              ? selectedFeature.imageEnSrc
              : selectedFeature.imageSrc
          }
          className={classes.appInfoImage}
        />
        <img
          alt={selectedFeature.key}
          src={'/assets/image/app/MocNavCZ2.png'}
          className={classes.appInfoImage2}
        />
      </Box>
    </Box>
  );
};
