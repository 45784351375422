import React, {FunctionComponent, SVGProps} from 'react';
import {Button, Typography} from '@mui/material';
import {AppFeatureKey} from './AppImageInfo';
import {isMobile} from 'react-device-detect';

export interface AppFeatureButtonProps {
  featureKey: AppFeatureKey;
  selected: boolean;
  text: string;
  FeatureIcon: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
  onFeatureShow?: (shownFeature: AppFeatureKey) => void;
}
export const AppFeatureButton = ({
  featureKey,
  onFeatureShow,
  selected,
  text,
  FeatureIcon,
}: AppFeatureButtonProps): JSX.Element => {
  const styles = {
    appFeatureContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      cursor: 'pointer',
      padding: 0,
      margin: 0,
      marginTop: isMobile ? 0 : '0.5vw',
    },
    appFeatureText: {
      width: isMobile ? '25vw' : '15vw',
      color: selected ? '#F6B900' : '#474747',
      fontSize: isMobile ? '3.5vw' : '1.4vw',
      fontWeight: 'bold',
      position: 'relative',
      left: selected ? '0.6vw' : '0.1vw',
      textAlign: 'start',
    },
    icon: {
      // transform: `scale(calc(${
      //   (1 / 400) * Math.min(window.innerWidth, window.innerHeight)
      // }))`,
    },
  };

  return (
    <Button
      sx={styles.appFeatureContainer}
      onClick={() => onFeatureShow && onFeatureShow(featureKey)}
      onMouseOver={() => onFeatureShow && onFeatureShow(featureKey)}>
      <FeatureIcon
        fill={selected ? '#F6B900' : '#474747'}
        // stroke="none"

        style={{
          marginRight: isMobile ? 0 : '10px',
          transform: `scale(${
            selected ? (isMobile ? 0.7 : 1.1) : isMobile ? 0.6 : 1
          })`,
        }}
      />
      <Typography sx={styles.appFeatureText}>{text}</Typography>
    </Button>
  );
};
