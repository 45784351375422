export const StylesRow = {
  title: {
    fontWeight: 600,
    fontSize: 17,
    lineHeight: 1.25,
    paddingBottom: '3px',
    paddingTop: '5px',
  },
  loader: {
    color: 'grey',
  },
  list: {
    maxHeight: '66vh',
    minHeight: '20vh',
    overflowY: 'auto',
    marginBottom: '15px',
  },
  listItem: {
    backgroundColor: 'secondary.main',
    cursor: 'pointer',
    padding: 0,
    paddingRight: '10px',
    margin: 0,
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  listItemSelected: {
    backgroundColor: 'primary.light',
  },
};
