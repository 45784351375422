import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  GenericState,
  getBaseObjList,
  getObj,
} from '@skczu/czu-frontend-library';
import {clearObjectPreview, setActiveObjectPreview} from '../DrawerSlice';
import config from '../../../config';
import {
  BaseRouteWeb,
  Route,
  RouteApi,
  WebRouteFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';

const initialState: GenericState<BaseRouteWeb, Route> = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const routeSlice = createGenericSlice({
  name: 'route',
  initialState,
})({});

export const getBaseRouteList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<BaseRouteWeb, WebRouteFilterResponse>(
        getState().routeList.baseObjSearch,
        getState().routeList.baseObjList,
        () =>
          new RouteApi(undefined, config.cmsRestUrl).routeWebFilterGet(
            keyword,
            getState().routeList.baseObjSearch.limit,
            getState().routeList.baseObjSearch.offset
          ),
        (loading) => dispatch(routeActions.setObjLoadingList(loading)),
        (fail) => dispatch(routeActions.setObjFailed({message: fail})),
        (offset) => dispatch(routeActions.setOffset(offset)),
        (newDataList) => {
          if (newList) {
            dispatch(
              routeActions.setBaseObjList(newDataList ? newDataList : [])
            );
          } else {
            dispatch(routeActions.addToBaseObjList(newDataList));
          }
        },
        keyword,
        (hasMore) => dispatch(routeActions.hasMore(hasMore))
      )
    );
  };

export const getRoute =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Route>(
        id,
        (routeId) =>
          new RouteApi(undefined, config.cmsRestUrl).routeIdGet(
            routeId as string
          ),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(routeActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(routeActions.setObj(obj));
        }
      )
    );
  };

export const getRouteForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(clearObjectPreview());
    dispatch(
      getObj<Route>(
        id,
        (routeId) =>
          new RouteApi(undefined, config.cmsRestUrl).routeIdGet(
            routeId as string
          ),
        (loading) => dispatch(routeActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(routeActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'route',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
        }
      )
    );
  };

export const routeActions = routeSlice.actions;

export const routeSelector = (state: RootState): typeof state.routeList =>
  state.routeList;

export default routeSlice.reducer;
