import React, {useEffect} from 'react';
import {
  createTheme,
  responsiveFontSizes,
  Theme,
  ThemeProvider,
} from '@mui/material';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {MapPage} from './components/scenes/map-scene/MapPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './localization/i18n';
import {MapLoadingIndicator, AppVersion} from '@skczu/czu-react-components';
import {useAppDispatch, useAppSelector} from './hooks/hooks';
import {loadingSelector} from './redux/reducers/LoadingSlice';
import config from './config';
import {AppSignpost} from './components/scenes/app-signpost/AppSignpost';
import {BrowserView, MobileView} from 'react-device-detect';
import {setSelectedMapGroup} from './redux/reducers/MapSlice';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localization from 'moment/locale/cs';
import moment from 'moment/moment';
import i18n from 'i18next';
moment.locale();

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const SecondaryLightHover = '#cccccc';

export let theme = createTheme({
  palette: {
    primary: {
      light: '#dafdcc',
      main: '#73be46',
      dark: '#303030',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#e0e0e0',
      main: '#ffffff',
      dark: '#303030',
      contrastText: '#000',
    },
  },
});
theme = responsiveFontSizes(theme);
export const App = (): JSX.Element => {
  const {isLoading, loadingCounter} = useAppSelector(loadingSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    i18n.language && moment.locale(i18n.language, localization);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserView>
        <MapLoadingIndicator showLoading={isLoading || loadingCounter > 0} />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                const urlParams = new URLSearchParams(location.search);
                const token = urlParams.get('event');
                token && dispatch(setSelectedMapGroup(token));
                return <Redirect to="/map" />;
              }}
            />
            <Route exact path="/signpost">
              <AppSignpost />
            </Route>
            <Route path={'/map'}>
              <MapPage />
            </Route>
            <Route render={() => <Redirect to="/" />} />
            {/*<PrivateRoute*/}
            {/*  path={['/map', '/users', '/news']}*/}
            {/*  component={MapPage}*/}
            {/*/>*/}
          </Switch>
        </Router>
      </BrowserView>
      {config?.environmentName !== 'Prod' && (
        <AppVersion
          appVersion={config?.appVersion}
          envName={config?.environmentName}
        />
      )}
      <MobileView>
        <Router>
          <Switch>
            <Route exact path={'/signpost'}>
              <AppSignpost />
            </Route>
            <Route
              component={() => {
                window.location.href =
                  `https://${config.environmentName !== 'Prod' ? 'my.' : ''}` +
                  config.environmentUrl +
                  '/';
                return null;
              }}
            />
          </Switch>
        </Router>
      </MobileView>
    </ThemeProvider>
  );
};

export default App;
