import React, {FunctionComponent, SVGProps, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AppInfo} from './AppInfo';
import {ReactComponent as Maps3D} from '../icons/3dMaps.svg';
import {ReactComponent as VirtualTour} from '../icons/VirtualTour.svg';
import {ReactComponent as PontOfInterest} from '../icons/PontOfInterest.svg';
import {ReactComponent as Navigation} from '../icons/Navigation.svg';
import {ReactComponent as Discover} from '../icons/Discover.svg';
import {ReactComponent as News} from '../icons/News.svg';
import {ReactComponent as Apps} from '../icons/Apps.svg';
import {AppFeatureButton} from './AppFeatureButton';
import {AppImageMenu} from './AppImageMenu';
import {useTranslation} from 'react-i18next';

export type AppFeatureKey =
  | '3dMap'
  | 'panorama'
  | 'poi'
  | 'navigation'
  | 'discover'
  | 'apps'
  | 'news';

export interface TextList {
  title?: string;
  description?: string;
}

export interface AppFeature {
  name: string;
  text: string;
  subtitle?: string;
  description?: string;
  textList?: TextList[];
  imageSrc: string;
  imageEnSrc: string;
  key: AppFeatureKey;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {title?: string | undefined}
  >;
}

const useStyles = makeStyles(() => ({
  appInfoImageContainer: {
    display: 'flex',
    // marginTop: 20,
    justifyContent: 'center',
  },
  appFeatureContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appImageText: {},
}));

const AppFeaturesList: AppFeature[] = [
  {
    name: '3D maps',
    key: '3dMap',
    icon: Maps3D,
    text: 'Discover the CZU university campus with high quality 3D maps.',
    imageSrc: '/assets/image/app/3D.png',
    imageEnSrc: '/assets/image/app/3DEN.png',
  },
  {
    name: 'Virtual tour',
    key: 'panorama',
    icon: VirtualTour,
    text: 'Walk the CZU university campus virtually with 360 photos.',
    imageSrc: '/assets/image/app/VR.png',
    imageEnSrc: '/assets/image/app/VREN.png',
  },
  {
    name: 'Points of interest',
    key: 'poi',
    icon: PontOfInterest,
    text: 'Quickly find what you are looking for.',
    description:
      'Cafeteria, parking or the place of your next lesson and much more',
    imageSrc: '/assets/image/app/POI.png',
    imageEnSrc: '/assets/image/app/POIEN.png',
  },
  {
    name: 'Navigation',
    key: 'navigation',
    icon: Navigation,
    text: 'Easily navigate to points of interest in the campus.',
    imageSrc: '/assets/image/app/Nav.png',
    imageEnSrc: '/assets/image/app/NavEN.png',
  },
  {
    name: 'Discover',
    key: 'discover',
    icon: Discover,
    text: 'Explore the university and pick places you would like to visit.',
    imageSrc: '/assets/image/app/Disc.png',
    imageEnSrc: '/assets/image/app/DiscEN.png',
  },
  {
    name: 'Apps',
    key: 'apps',
    icon: Apps,
    subtitle:
      'Number of powerful apps makes your life in the campus productive and fun.',
    text: 'Students and employees of CZU will have a full range of applications available after logging in',
    textList: [
      {
        title: 'Food menu',
        description:
          'Browse the wide range of food and establishments where you can eat or enjoy a coffee and cake',
      },
      {
        title: 'My cars',
        description:
          'Set your car license plate and ramps will automatically open for your car when you enter the CZU campus',
      },
      {
        title: 'QR scanner',
        description: 'View the information hidden behind QR codes',
      },
      {
        title: 'Actions',
        description:
          'Navigate the points of interest of the event during its duration at the university',
      },
      {
        title: 'News',
        description:
          "Get all the important information and alerts about what's going on at the university",
      },
      {
        title: 'My wallet',
        description: 'Check the status of your card and JUMBO account',
      },
      {
        title: 'Contacts',
        description: 'Call, write, or look up your instructor if needed',
      },
      {
        title: 'Study info – index',
        description:
          'Register for an exam and browse the e-index, information about active study and your scholarships',
      },
      {title: 'and more coming soon'},
    ],
    imageSrc: '/assets/image/app/App.png',
    imageEnSrc: '/assets/image/app/AppEN.png',
  },
  {
    name: 'News',
    key: 'news',
    icon: News,
    text: 'Stay tuned with latest university news and receive notifications about upcoming actions.',
    imageSrc: '/assets/image/app/Novinky.png',
    imageEnSrc: '/assets/image/app/NovinkyEN.png',
  },
];

const featureSelected = (
  selectedFeature: AppFeatureKey | undefined,
  featureKey: AppFeatureKey
): boolean => {
  return selectedFeature === featureKey;
};

export const AppImageInfo = (): JSX.Element => {
  const {t} = useTranslation();
  const [appFeatures] = useState<AppFeature[]>(AppFeaturesList);
  const [selected, setSelected] = useState<AppFeature>(appFeatures[0]);
  const classes = useStyles();

  const renderFeatureList = useMemo(() => {
    return appFeatures.map((feature, index) => (
      <AppFeatureButton
        selected={featureSelected(selected.key, feature.key)}
        featureKey={feature.key}
        key={feature.key + '_' + index}
        text={t(`signPost.${feature.name}`).toUpperCase()}
        FeatureIcon={feature.icon}
        onFeatureShow={(featureKey) =>
          setSelected(
            appFeatures.find(
              (feature) => feature.key === featureKey
            ) as AppFeature
          )
        }
      />
    ));
  }, [appFeatures, selected.key, t]);

  const renderFeatures = useMemo(() => {
    return (
      <>
        <AppImageMenu features={renderFeatureList} selectedFeature={selected} />
        {selected && (
          <AppInfo
            name={selected.name}
            text={selected.text}
            description={selected.description}
            textList={selected.textList}
            subtitle={selected.subtitle}
          />
        )}
      </>
    );
  }, [renderFeatureList, selected]);

  return (
    <Box className={classes.appInfoImageContainer}>
      <Box className={classes.appFeatureContainer}>{renderFeatures}</Box>
    </Box>
  );
};
