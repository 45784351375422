import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  GenericState,
  getBaseObjList,
  getObj,
} from '@skczu/czu-frontend-library';
import config from '../../../config';
import {
  EventFilterRefObject,
  EventApi,
  Event,
  EventFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import {setActiveObjectPreview} from '../DrawerSlice';
import {
  ActivatedEvent,
  getActiveActions,
} from '../../../components/shared/utils/ActiveUtils';
import {setSelectedGroups} from '../MapSlice';
import {
  DefaultEvent,
  DefaultEventApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/defaultEvent';
import {PayloadAction} from '@reduxjs/toolkit';

export interface EventsState extends GenericState<EventFilterRefObject, Event> {
  defaultEvent?: DefaultEvent;
  activatedEvents?: ActivatedEvent[];
}

const initialState: EventsState = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
  filterDataList: [],
  filterObjSearch: {
    limit: 10,
    offset: 0,
  },
};

const groupsSlice = createGenericSlice({
  name: 'groups',
  initialState,
})({
  setDefaultEvent: (
    state: EventsState,
    {payload}: PayloadAction<DefaultEvent>
  ) => {
    state.defaultEvent = payload;
  },
  setActivatedEvents: (
    state: EventsState,
    {payload}: PayloadAction<ActivatedEvent[]>
  ) => {
    state.activatedEvents = payload;
  },
  changeActivatedEvent: (
    state,
    {payload}: PayloadAction<EventFilterRefObject>
  ) => {
    if (state.activatedEvents) {
      state.activatedEvents = state.activatedEvents.map((event) => {
        if (event.activatedEvent.id === payload.id) {
          return {...event, changedSelection: true};
        }
        return event;
      });
    }
  },
});

// export const getFilterGroupsList =
//   (newList: boolean, keyword?: string, onGetBaseList?: () => void): AppThunk =>
//   async (dispatch, getState) => {
//     dispatch(
//       getBaseObjList<EventFilterRefObject, EventFilterResponse>(
//         getState().groupsList.filterObjSearch as InfiniteSearch,
//         getState().groupsList.filterDataList as EventFilterRefObject[],
//         () =>
//           new EventApi(undefined, config.cmsRestUrl).eventFilterGet(
//             keyword,
//             9999,
//             0,
//             true,
//             true
//           ),
//         (loading) => dispatch(setLoading(loading)),
//         (fail) => dispatch(groupActions.setObjFailed({message: fail})),
//         () => void 0,
//         (data) => {
//           dispatch(groupActions.setFilterDataList(data));
//           onGetBaseList && onGetBaseList();
//         },
//         keyword
//       )
//     );
//   };

export const getBaseGroupsList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<EventFilterRefObject, EventFilterResponse>(
        getState().groupsList.baseObjSearch,
        getState().groupsList.baseObjList,
        () =>
          new EventApi(undefined, config.cmsRestUrl).eventFilterGet(
            keyword,
            getState().groupsList.baseObjSearch.limit,
            getState().groupsList.baseObjSearch.offset,
            undefined,
            true
          ),
        (loading) => dispatch(groupActions.setObjLoadingList(loading)),
        (fail) => dispatch(groupActions.setObjFailed({message: fail})),
        (offset) => dispatch(groupActions.setOffset(offset)),
        (eventsDataList) =>
          newList
            ? dispatch(
                groupActions.setBaseObjList(
                  eventsDataList ? eventsDataList : []
                )
              )
            : dispatch(groupActions.addToBaseObjList(eventsDataList)),
        keyword,
        (hasMore) => dispatch(groupActions.hasMore(hasMore))
      )
    );
  };

export const getFilterGroupsList =
  (newList: boolean, keyword?: string, onGetBaseList?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(groupActions.setObjLoadingList(true));

      const response = await new EventApi(
        undefined,
        config?.cmsRestUrl
      ).eventFilterGet(keyword, 9999, 0, true);
      if (response.data?.objects) {
        const eventsWithDates: EventFilterRefObject[] = response.data.objects;
        const selectedEvents = getState().map.mapFilter.selectedGroups;
        const activatedEvents = getState().groupsList.activatedEvents;
        const {newSelectedUserEvents, newActivatedEvents} = getActiveActions(
          selectedEvents,
          eventsWithDates.filter((event) => event.visibleOnMap),
          activatedEvents
        );
        dispatch(groupActions.setActivatedEvents(newActivatedEvents));
        dispatch(groupActions.setFilterDataList(eventsWithDates));
        if (
          JSON.stringify(selectedEvents) !==
          JSON.stringify(newSelectedUserEvents)
        ) {
          dispatch(setSelectedGroups(newSelectedUserEvents));
        }
        onGetBaseList && onGetBaseList();
      }
    } catch (error) {
      // console.log(JSON.stringify(error));
    } finally {
      dispatch(groupActions.setObjLoadingList(false));
    }
  };

export const getDefaultEvent = (): AppThunk => async (dispatch) => {
  try {
    dispatch(groupActions.setObjLoadingList(true));
    const response = await new DefaultEventApi(
      undefined,
      config?.cmsRestUrl
    ).defaultEventGet();
    if (response?.data) {
      dispatch(groupActions.setDefaultEvent(response?.data));
    }
  } catch (error) {
    // console.log('Failed to load events');
  } finally {
    dispatch(groupActions.setObjLoadingList(false));
  }
};

export const getGroupForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<Event>(
        id,
        () => new EventApi(undefined, config.cmsRestUrl).eventIdGet(id),
        (loading) => dispatch(groupActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(groupActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'group',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
        }
      )
    );
  };

export const groupActions = groupsSlice.actions;

export const groupsSelector = (state: RootState): typeof state.groupsList =>
  state.groupsList;

export const defaultEventSelector = (
  state: RootState
): typeof state.groupsList.defaultEvent => state.groupsList.defaultEvent;

export default groupsSlice.reducer;
