import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Box, CircularProgress, Divider} from '@mui/material';
import {StylesRow} from './menu-rows/StylesRow';
import {useAppSelector} from '../../../../../hooks/hooks';
import {poiCategoriesSelector} from '../../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import {PoiMenuRow} from './menu-rows/PoiMenuRow';
import {RouteMenuRow} from './menu-rows/RouteMenuRow';
import {ListItemRow} from '@skczu/czu-frontend-library';
import {BasePoiWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {BaseRouteWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {BaseNewsWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {ImageListItem} from './menu-rows/ImageListItem';

export interface CustomMenuListProps {
  listItems: ListItemRow[];
  handleItemClick?: (id: string) => void;
  loadingList?: boolean;
  hasMore: boolean;
  handleLoadMore?: () => void;
  scrollTo?: string;
}

export const PanelMenuList = ({
  listItems,
  handleItemClick,
  loadingList,
  hasMore,
  handleLoadMore,
  scrollTo,
}: CustomMenuListProps): JSX.Element => {
  const poiCategoryState = useAppSelector(poiCategoriesSelector);
  const itemsRef = useRef<Record<string, HTMLDivElement | null>>({});

  useEffect(() => {
    if (
      scrollTo &&
      itemsRef &&
      itemsRef.current &&
      itemsRef.current[scrollTo]
    ) {
      const itemRef = itemsRef.current[scrollTo];
      itemRef?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [scrollTo]);

  const getCategoryIcon = useCallback(
    (categoryId?: string) =>
      poiCategoryState.baseObjList &&
      poiCategoryState.baseObjList.find(
        (category) => category.id === categoryId
      ),
    [poiCategoryState.baseObjList]
  );

  const renderList = useMemo(() => {
    return listItems.map((listItem) => (
      <Box key={listItem.id} style={{height: '100%'}}>
        {listItem.dataType === 'poi' && (
          <PoiMenuRow
            listItem={listItem}
            poiCategory={getCategoryIcon(
              (listItem.data as BasePoiWeb).category
            )}
            basePoi={listItem.data as BasePoiWeb}
            handleItemClick={handleItemClick}
            onRef={(el) => listItem.id && (itemsRef.current[listItem.id] = el)}
          />
        )}
        {listItem.dataType === 'route' && (
          <RouteMenuRow
            listItem={listItem}
            baseObj={listItem.data as BaseRouteWeb}
            handleItemClick={handleItemClick}
          />
        )}
        {(listItem.dataType === 'news' || listItem.dataType === 'event') && (
          <ImageListItem
            listItem={listItem}
            baseObj={listItem.data as BaseNewsWeb}
            handleItemClick={handleItemClick}
          />
        )}
        <Divider />
      </Box>
    ));
  }, [getCategoryIcon, handleItemClick, listItems]);

  return (
    <Box>
      <InfiniteScroll
        style={{
          maxHeight: '66vh',
          minHeight: '20vh',
          overflowY: 'auto',
          marginBottom: 15,
        }}
        dataLength={listItems.length}
        next={() => handleLoadMore && handleLoadMore()}
        hasMore={hasMore}
        loader={
          loadingList !== undefined &&
          !loadingList && (
            <Box marginTop={1} textAlign={'center'}>
              <CircularProgress sx={StylesRow.loader} />
            </Box>
          )
        }
        height={'100%'}>
        {renderList}
        {loadingList && (
          <Box marginTop={1} textAlign={'center'}>
            <CircularProgress sx={StylesRow.loader} />
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};
