import React from 'react';
import {Box, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {ListItemRow} from '@skczu/czu-frontend-library';
import {PanoramaIcon} from '@skczu/czu-react-components';
import {PoiMenuRowImage} from './PoiMenuRowImage';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {BasePoiWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {StylesRow} from './StylesRow';

const useStyles = makeStyles((theme) => ({
  categoryPanel: {
    minHeight: '100%',
    minWidth: 10,
    maxWidth: 10,
    marginRight: 10,
  },
  poiPanel: {
    flex: '1 1 auto',
    alignSelf: 'flex-start',
    width: '60%',
  },
  poiPanelText: {
    paddingBottom: 26,
    alignSelf: 'flex-start',
  },
  categoryText: {
    color: theme.palette.grey[500],
  },
  poiPanelButtons: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ARButton: {
    fontSize: 15,
    fontWeight: 'bold',
    marginRight: 5,
    marginLeft: 5,
    color: theme.palette.secondary.contrastText,
  },
  image: {
    marginTop: 2,
    marginBottom: 2,
    minWidth: 100,
    width: 100,
    height: 100,
    display: 'flex',
    alignSelf: 'center',
    objectFit: 'cover',
  },
}));

export interface PoiMenuRowProps {
  listItem: ListItemRow;
  poiCategory?: PoiCategory;
  basePoi: BasePoiWeb;
  onRef?: (el: HTMLDivElement | null) => void;
  handleItemClick?: (id: string) => void;
}

export const PoiMenuRow = ({
  listItem,
  poiCategory,
  basePoi,
  onRef,
  handleItemClick,
}: PoiMenuRowProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        ...(listItem.selectedItem
          ? {...StylesRow.listItem, ...StylesRow.listItemSelected}
          : StylesRow.listItem),
      }}
      component={'div'}
      key={listItem.id}
      onClick={() => handleItemClick && handleItemClick(listItem.id)}
      ref={(el) => onRef && onRef(el as HTMLDivElement | null)}>
      <Box
        className={classes.categoryPanel}
        style={{backgroundColor: poiCategory?.color}}
      />
      <Box className={classes.poiPanel}>
        <Box className={classes.poiPanelText}>
          <Typography variant={'subtitle1'} sx={StylesRow.title}>
            {listItem.itemRowName}
          </Typography>
          <Typography variant={'body2'} className={classes.categoryText}>
            {poiCategory &&
              CustomUtils.getContentByLanguage(
                poiCategory.name,
                poiCategory.nameEn
              )}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.poiPanelButtons}>
        {basePoi.view360 && (
          <img src={PanoramaIcon} alt={'panorama'} height={27} width={27} />
        )}
        {basePoi.enabledArImage && <Box className={classes.ARButton}>AR</Box>}
      </Box>
      <PoiMenuRowImage imageSource={basePoi.image} />
    </Box>
  );
};
