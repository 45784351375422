import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box} from '@mui/material';
import {FeedbackEmoticon} from './FeedbackEmtoicon';

export interface FeedbackEmoticonSelectionProps {
  setEmoticonRating: (emoticonId: number) => void;
  rating?: number;
}

export const FeedbackEmoticonSelection = ({
  setEmoticonRating,
  rating,
}: FeedbackEmoticonSelectionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.containerEmoticons}>
      <FeedbackEmoticon
        active={rating === 1}
        onPress={() => setEmoticonRating(1)}
        iconSrc={'/assets/icon/feedback/VeryDissatisfiedSVG.svg'}
      />
      <FeedbackEmoticon
        active={rating === 2}
        onPress={() => setEmoticonRating(2)}
        iconSrc={'/assets/icon/feedback/MoodBadSVG.svg'}
      />
      <FeedbackEmoticon
        active={rating === 3}
        onPress={() => setEmoticonRating(3)}
        iconSrc={'/assets/icon/feedback/MoodNeutralSVG.svg'}
      />
      <FeedbackEmoticon
        active={rating === 4}
        onPress={() => setEmoticonRating(4)}
        iconSrc={'/assets/icon/feedback/MoodSVG.svg'}
      />
      <FeedbackEmoticon
        active={rating === 5}
        onPress={() => setEmoticonRating(5)}
        iconSrc={'/assets/icon/feedback/VerySatisfiedSVG.svg'}
      />
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  containerEmoticons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 10,
  },
}));
