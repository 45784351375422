import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../HeaderMenuItem';
import {useTranslation} from 'react-i18next';
import {
  selectDrawer,
  clearObjectPreview,
} from '../../../../../../redux/reducers/DrawerSlice';
import {StylesMenu} from '../StylesMenu';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {
  getBasePoiList,
  getPoiForMobilePreview,
  poiActions,
  poiSelector,
} from '../../../../../../redux/reducers/data-reducers/PoiSlice';
import {PanelMenuList} from '../PanelMenuList';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {useHistory, useParams} from 'react-router-dom';
import {CategoryChip} from './CategoryChip';
import {poiCategoriesSelector} from '../../../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {ListItemRow, useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {
  SearchInput,
  ListLoadingIndicator,
  PoiCategoryIconList,
} from '@skczu/czu-react-components';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';

export const ListOfPoisTab = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const menuClasses = StylesMenu();
  const dispatch = useAppDispatch();
  const {
    loadingList,
    loadingDetail,
    baseObjList,
    baseObjSearch,
    hasMore,
    filterCategory,
  } = useAppSelector(poiSelector);
  const {objectPreview, objectPreviewView} = useAppSelector(selectDrawer);
  const {poiId} = useParams<{poiId: string}>();
  const [scrollIndex, setScrollIndex] = useState<string | undefined>();
  const poiCategoryState = useAppSelector(poiCategoriesSelector);
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    poiActions,
    dispatch,
    baseObjSearch,
    getBasePoiList
  );

  useEffect(() => {
    poiId &&
      dispatch(getPoiForMobilePreview(poiId, (id) => setScrollIndex(id)));
    !poiId && dispatch(clearObjectPreview());
  }, [dispatch, poiId]);

  const handleItemClick = useCallback(
    (id: string) => {
      history.push(`/map/poi/${id}`);
    },
    [history]
  );

  const getPoiListItems = useCallback((): ListItemRow[] => {
    return baseObjList
      ? baseObjList.map((poi) => {
          return {
            id: poi.id,
            itemRowName: CustomUtils.getContentByLanguage(poi.name, poi.nameEn),
            dataType: 'poi',
            data: poi,
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === poi.id
                ? true
                : undefined
              : undefined,
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    objectPreviewView.openObjectPreview,
    objectPreview.objectPreviewData,
  ]);

  const handleSelectCategory = useCallback(
    (category: PoiCategory) => {
      dispatch(poiActions.setSelectedFilterCategory(category));
      dispatch(poiActions.resetSearch());
      dispatch(
        getBasePoiList(
          true,
          baseObjSearch.searchQuery ? baseObjSearch.searchQuery : undefined
        )
      );
    },
    [baseObjSearch.searchQuery, dispatch]
  );

  const handleUnselectCategory = useCallback(() => {
    dispatch(poiActions.setSelectedFilterCategory(undefined));
    dispatch(poiActions.resetSearch());
    dispatch(
      getBasePoiList(
        true,
        baseObjSearch.searchQuery ? baseObjSearch.searchQuery : undefined
      )
    );
  }, [baseObjSearch.searchQuery, dispatch]);

  const renderList = useMemo(() => {
    return (
      <PanelMenuList
        scrollTo={scrollIndex}
        listItems={getPoiListItems()}
        handleItemClick={handleItemClick}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
    );
  }, [
    getPoiListItems,
    handleItemClick,
    handleLoadMore,
    hasMore,
    loadingList,
    scrollIndex,
  ]);

  const renderCategoryList = useMemo(() => {
    t('');
    return (
      <>
        <PoiCategoryIconList
          categoryList={poiCategoryState.baseObjList}
          onSelectCategory={handleSelectCategory}
        />
        {filterCategory && (
          <CategoryChip
            filterCategory={filterCategory}
            onDelete={handleUnselectCategory}
          />
        )}
      </>
    );
  }, [
    filterCategory,
    handleSelectCategory,
    handleUnselectCategory,
    poiCategoryState.baseObjList,
    t,
  ]);

  return (
    <Box className={menuClasses.menu}>
      <HeaderMenuItem name={t('leftMenu.Points of interest')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          placeholder={t('leftMenu.search')}
          autoFocus={true}
          name={'poi-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <Box className={menuClasses.categoryList}>{renderCategoryList}</Box>
      <ListLoadingIndicator showLoading={loadingDetail} />
      {renderList}
    </Box>
  );
};
