import React, {ReactNode} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {isMobile} from 'react-device-detect';

const styles = {
  text: {
    color: 'secondary.main',
    fontSize: isMobile ? '4vw' : '0.8vw',
  },
  name: {
    color: 'secondary.main',
    fontSize: isMobile ? '6vw' : '1.3vw',
    fontWeight: 'bold',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    justifyContent: 'center',
  },
  container: {
    textTransform: 'none',
    width: isMobile ? '60vw' : '13.5vw',
    zIndex: 2,
    display: 'flex',
    marginRight: '1vw',
    marginLeft: '1vw',
    marginTop: '4vw',
    padding: isMobile ? '2vw' : '0.5vw',
    backgroundColor: 'primary.dark',
    borderRadius: isMobile ? '2vw' : '0.6vw',
    justifyContent: 'start',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
};
export interface PlatformButtonProps {
  text: string;
  name: string;
  onClick?: () => void;
  icon: ReactNode;
}
export const PlatformButton = ({
  name,
  icon,
  onClick,
  text,
}: PlatformButtonProps): JSX.Element => {
  return (
    <Button
      type="button"
      id="but"
      name="nbut"
      sx={styles.container}
      onClick={onClick}>
      {icon}
      <Box sx={styles.textContainer}>
        <Typography sx={styles.text}>{text}</Typography>
        <Typography sx={styles.name}>{name}</Typography>
      </Box>
    </Button>
  );
};
