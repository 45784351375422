import React from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AppImageInfo} from './app-info/AppImageInfo';
import {isMobile} from 'react-device-detect';
import {SignpostHeader} from './header/SignpostHeader';
import {FooterHeight, SignpostFooter} from './footer/SignpostFooter';

const useStyles = makeStyles(() => ({
  body: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {flexGrow: 1},
  backgroundImageContainer: {
    width: '100%',
    height: isMobile ? '300vw' : '100vh',
    position: 'absolute',
    top: 0,
    zIndex: -1,
  },
  backgroundImage: {
    width: isMobile ? '130vw' : '100%',
    height: '100%',
    zIndex: -1,
    position: 'absolute',
    left: isMobile ? '-30vw' : 0,
    backgroundImage: isMobile
      ? `url(/assets/image/app/CampusPreviewMobile.png)`
      : `url(/assets/image/app/CampusPreview.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: isMobile ? `300vw 150vw` : `100vw 44vw`,
  },
  appInfoContainer: {
    marginTop: isMobile ? '16vw' : '5vw',
    paddingBottom: FooterHeight,
  },
}));

export const AppSignpost = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.body}>
      <Box className={classes.content}>
        <Box className={classes.backgroundImageContainer}>
          <Box className={classes.backgroundImage} />
        </Box>
        <SignpostHeader />
        <Box className={classes.appInfoContainer}>
          <AppImageInfo />
        </Box>
      </Box>
      <SignpostFooter />
    </Box>
  );
};
