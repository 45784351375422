import React from 'react';
import {Box, Chip} from '@mui/material';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';

const styles = {
  chip: {
    borderRadius: 10,
    marginTop: '10px',
    marginLeft: '-20px',
    color: 'secondary.main',
  },
  chipContainer: {
    display: 'flex',
    marginBottom: '5px',
  },
  chipLabel: {
    marginTop: '10px',
    marginLeft: '4px',
    fontWeight: 'bold',
    fontSize: 19,
  },
};

export interface CategoryChipProps {
  filterCategory: PoiCategory;
  onDelete: () => void;
}
export const CategoryChip = ({
  filterCategory,
  onDelete,
}: CategoryChipProps): JSX.Element => {
  return (
    <Chip
      sx={styles.chip}
      style={{backgroundColor: filterCategory.color}}
      label={
        <Box sx={styles.chipContainer}>
          <Box>
            <img
              alt={filterCategory?.name}
              style={{marginTop: 10}}
              src={filterCategory?.icon?.url}
              height={22}
              width={22}
            />
          </Box>
          <Box sx={styles.chipLabel}>
            {CustomUtils.getContentByLanguage(
              filterCategory.name,
              filterCategory.nameEn
            )}
          </Box>
        </Box>
      }
      onDelete={onDelete}
      variant="outlined"
    />
  );
};
