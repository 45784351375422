import moment from 'moment/moment';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
export interface ActivatedEvent {
  activatedEvent: EventFilterRefObject;
  changedSelection: boolean;
}

export const getActiveActions = (
  selectedEvents: EventFilterRefObject[],
  newEventsWithDates: EventFilterRefObject[],
  activatedEvents?: ActivatedEvent[]
): {
  newSelectedUserEvents: EventFilterRefObject[];
  newActivatedEvents: ActivatedEvent[];
} => {
  let newSelectedUserEvents: EventFilterRefObject[] = [];
  let newActivatedEvents: ActivatedEvent[] = [];
  if (selectedEvents?.length > 0) {
    selectedEvents.forEach((selectedEvent) => {
      const foundEvent = newEventsWithDates.find(
        (event) => event?.id === selectedEvent?.id
      );
      foundEvent && newSelectedUserEvents.push(selectedEvent);
    });
  }
  if (activatedEvents && activatedEvents.length > 0) {
    return getNewSelectedEventsFromActivated(
      activatedEvents,
      getNewActiveEvents(newEventsWithDates),
      newSelectedUserEvents
    );
  } else {
    newSelectedUserEvents = getNewActiveEvents(newEventsWithDates);
    newActivatedEvents = newSelectedUserEvents.map((event) => ({
      activatedEvent: event,
      changedSelection: false,
    }));
  }
  return {newSelectedUserEvents, newActivatedEvents};
};

export const getNewActiveEvents = (
  eventsWithDates: EventFilterRefObject[]
): EventFilterRefObject[] => {
  return eventsWithDates.filter(
    (action) =>
      (action.startDate &&
        action.endDate &&
        new Date().getTime() >= moment(action.startDate).valueOf() &&
        new Date().getTime() <= moment(action.endDate).valueOf()) ||
      (action.startDate &&
        !action.endDate &&
        new Date().getTime() >= moment(action.startDate).valueOf())
  );
};

export const getNewSelectedEventsFromActivated = (
  oldActivatedEvents: ActivatedEvent[],
  newActiveEvents: EventFilterRefObject[],
  selectedEvents: EventFilterRefObject[]
): {
  newSelectedUserEvents: EventFilterRefObject[];
  newActivatedEvents: ActivatedEvent[];
} => {
  const newSelectedUserEvents: EventFilterRefObject[] = [];
  const newActivatedEvents: ActivatedEvent[] = [];

  selectedEvents?.forEach((event) => {
    !oldActivatedEvents.some(
      (activatedEvent) => activatedEvent?.activatedEvent.id === event?.id
    ) && newSelectedUserEvents.push(event);
  });
  newActiveEvents?.forEach((event) => {
    const foundEvent = oldActivatedEvents.find(
      (activatedEvent) => activatedEvent?.activatedEvent.id === event?.id
    );
    if (foundEvent) {
      const {newSelectedUserEvent, newActivatedEvent} =
        getEventForEventSelection(event, foundEvent, selectedEvents);
      newSelectedUserEvent && newSelectedUserEvents.push(newSelectedUserEvent);
      newActivatedEvent && newActivatedEvents.push(newActivatedEvent);
    } else {
      !selectedEvents?.some((oldSelected) => oldSelected?.id === event.id) &&
        newSelectedUserEvents.push(event);
      newActivatedEvents.push({activatedEvent: event, changedSelection: false});
    }
  });
  return {newSelectedUserEvents, newActivatedEvents};
};

export const getEventForEventSelection = (
  event: EventFilterRefObject,
  foundEvent: ActivatedEvent,
  selectedEvents: EventFilterRefObject[]
): {
  newSelectedUserEvent: EventFilterRefObject | undefined;
  newActivatedEvent: ActivatedEvent;
} => {
  if (
    selectedEvents?.some(
      (oldSelected) => oldSelected?.id === foundEvent.activatedEvent.id
    )
  ) {
    return {
      newSelectedUserEvent: event,
      newActivatedEvent: {
        activatedEvent: event,
        changedSelection: foundEvent.changedSelection,
      },
    };
  } else {
    const addToSelected =
      !foundEvent.changedSelection ||
      (foundEvent.changedSelection &&
        foundEvent.activatedEvent.modifiedDate !== event.modifiedDate);

    return {
      newSelectedUserEvent: addToSelected ? event : undefined,
      newActivatedEvent: {
        activatedEvent: event,
        changedSelection: foundEvent.changedSelection,
      },
    };
  }
};
