import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {DamFile} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';

const useStyles = makeStyles(() => ({
  image: {
    marginTop: 2,
    marginBottom: 2,
    minWidth: 100,
    width: 100,
    height: 100,
    display: 'flex',
    alignSelf: 'center',
    objectFit: 'cover',
  },
}));

export interface PoiMenuRowImageProps {
  imageSource?: DamFile;
}

export const PoiMenuRowImage = ({
  imageSource,
}: PoiMenuRowImageProps): JSX.Element => {
  const classes = useStyles();

  return imageSource ? (
    <img
      alt={imageSource.fileName}
      src={imageSource.thumbnailUrl}
      className={classes.image}
    />
  ) : (
    <></>
  );
};
