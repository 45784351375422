import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BillboardGraphics,
  CesiumComponentRef,
  Entity,
  ImageryLayer,
  Viewer,
  CustomDataSource as ResiumCustomDataSource,
} from 'resium';
import {
  Cartesian3,
  Color,
  Math as CesiumMath,
  Rectangle,
  Cesium3DTileset,
  Viewer as CesiumViewer,
  WebMapServiceImageryProvider,
  PolylineDashMaterialProperty,
  CustomDataSource,
  Entity as CesiumEntity,
  Cartographic,
  Matrix4,
  Ion,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined,
  Event,
} from 'cesium';
import axios from 'axios';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import viewerCesiumNavigationMixin from '@znemz/cesium-navigation';
import '@znemz/cesium-navigation/dist/index.css';
import {useHistory} from 'react-router-dom';
import {
  selectMap,
  setMarkersList,
  setPoiMarkers,
} from '../../../../redux/reducers/MapSlice';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {poiCategoriesSelector} from '../../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {selectDrawer} from '../../../../redux/reducers/DrawerSlice';
import {object3dSelector} from '../../../../redux/reducers/data-reducers/Object3dSlice';
import {
  loadingSelector,
  setMapLoading,
} from '../../../../redux/reducers/LoadingSlice';
import {MapLoadingIndicator} from '@skczu/czu-react-components';
import './MapStyles.scss';
import config from '../../../../config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GraphHopperRouting from 'graphhopper-js-api-client/src/GraphHopperRouting';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GHInput from 'graphhopper-js-api-client/src/GHInput';
import {Feature, Point} from 'geojson';
import {
  BBOX,
  VehicleType,
  MapUtils,
  CategoryPinBuilderIcon,
} from '@skczu/czu-frontend-library';
import {getGraphhopperRoute} from '../../../../hooks/GraphopperService';
import {
  Coordinates,
  Poi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {Route} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/route';
import {PoiCategory} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poiCategory';
import {defaultEventSelector} from '../../../../redux/reducers/data-reducers/GroupsSlice';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Ion.defaultAccessToken = null;

export type Images = {[key: string]: HTMLImageElement};

const useStyles = makeStyles(() =>
  createStyles({
    map: {
      width: '100%',
      height: '100vh',
    },
  })
);
const imageryProviderUrl = config.osmWmsUrl;
const imageryProviderLayers = 'osm:osm';
const CampusCoords = {longitude: 14.374688, latitude: 50.13016};

export const MapComponent: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {isMapLoading} = useAppSelector(loadingSelector);
  const {objectPreviewView, objectPreview} = useAppSelector(selectDrawer);
  const poiCategoryState = useAppSelector(poiCategoriesSelector);
  const {mapType, mapFilter, markersList} = useAppSelector(selectMap);
  const defaultEvent = useAppSelector(defaultEventSelector);
  const object3dState = useAppSelector(object3dSelector);
  const [categoryPinBuilderIcons, setCategoryPinBuilderIcons] = useState<
    CategoryPinBuilderIcon[]
  >([]);
  const [routeEntities, setRouteEntities] = useState<CustomDataSource | null>(
    null
  );
  const selectedPoi = useRef<CustomDataSource | null>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapBBOX, setMapBBOX] = useState<BBOX | null>(null);
  const [viewer, setViewer] = useState<CesiumViewer | null>(null);
  const tilesetRef = useRef<Cesium3DTileset[] | null>();
  const cesiumRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const classes = useStyles();
  const navigateTo = useCallback((path) => history.push(path), [history]);
  const defaultImageryProvider = useMemo(
    () =>
      new WebMapServiceImageryProvider({
        layers: imageryProviderLayers,
        url: imageryProviderUrl,
        parameters: {
          format: 'image/jpeg',
          tiled: true,
          gridSet: 'EPSG:4326',
        },
      }),
    []
  );
  const mapEntityRef = useRef<
    Record<string, CesiumComponentRef<CesiumEntity> | null>
  >({});

  useLayoutEffect(() => {
    function updateSize() {
      if (viewer) {
        viewer.resolutionScale = window.devicePixelRatio;
        viewer.scene.requestRender();
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [viewer]);

  useEffect(() => {
    if (cesiumRef.current?.cesiumElement) {
      const cesiumElement = cesiumRef.current.cesiumElement;
      setViewer(cesiumElement);
      cesiumElement.resolutionScale = window.devicePixelRatio;
      cesiumElement.scene.globe.maximumScreenSpaceError = 1.65;
      cesiumElement.scene.postProcessStages.fxaa.enabled = false;

      cesiumElement.scene.skyBox.show = false;
      cesiumElement.scene.sun.show = false;
      cesiumElement.scene.sunBloom = false;
      cesiumElement.scene.skyAtmosphere.show = false;
      cesiumElement.scene.shadowMap.enabled = false;
      cesiumElement.scene.moon.show = false;
      cesiumElement.scene.globe.enableLighting = false;

      const options: Record<string, unknown> = {};
      options.defaultResetView = Cartographic.fromDegrees(
        CampusCoords.longitude - 0.003,
        CampusCoords.latitude,
        2000
      );
      options.enableCompass = true;
      options.enableZoomControls = true;
      options.enableDistanceLegend = false;
      options.units = 'kilometers';
      cesiumElement.extend(viewerCesiumNavigationMixin, options);
      cesiumElement.camera.flyTo({
        duration: 0,
        destination: Cartesian3.fromDegrees(
          CampusCoords.longitude - 0.003,
          CampusCoords.latitude,
          2000.0
        ),
      });
      cesiumElement.screenSpaceEventHandler.removeInputAction(3);
      const element = document.getElementById('cesiumContainer');
      const scene = cesiumElement?.scene;
      const handler = new ScreenSpaceEventHandler(scene?.canvas);
      handler.setInputAction(function (movement) {
        const pickedObject = scene?.pick(movement.endPosition);
        if (element?.style) {
          if (defined(pickedObject) && element?.style) {
            element.style.cursor = 'pointer';
          } else {
            element.style.cursor = 'default';
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
      let tilesListener: Event.RemoveCallback;
      if (!mapLoaded) {
        tilesListener =
          cesiumElement.scene.globe.tileLoadProgressEvent.addEventListener(
            () => {
              if (cesiumElement.scene.globe.tilesLoaded) {
                setMapLoaded(true);
              }
            }
          );
      }
      dispatch(setMapLoading(true));
      setTimeout(() => dispatch(setMapLoading(false)), 5000);
      return () => {
        tilesListener &&
          cesiumElement?.scene &&
          cesiumElement.scene.globe.tileLoadProgressEvent.removeEventListener(
            tilesListener
          );
      };
    }
  }, [dispatch, mapLoaded]);

  const flyToPoint = useCallback(
    (position: number[], height = 400) => {
      mapType === '2D'
        ? viewer?.camera.flyTo({
            duration: 1.5,
            destination: Cartesian3.fromDegrees(
              position[0],
              position[1],
              height
            ),
          })
        : viewer?.camera.flyTo({
            duration: 1.5,
            destination: Cartesian3.fromDegrees(
              position[0],
              position[1] - 0.0045,
              height
            ),
            orientation: {
              heading: CesiumMath.toRadians(0.0),
              pitch: CesiumMath.toRadians(-50.0),
              roll: 0.0,
            },
          });
    },
    [mapType, viewer]
  );

  const getTileset = useCallback(async (): Promise<Cesium3DTileset[]> => {
    return Promise.all(
      object3dState.baseObjList.map(async (baseObject3d) => {
        const cesiumTileset = viewer?.scene.primitives.add(
          new Cesium3DTileset({
            url: baseObject3d.tileUrl,
            maximumScreenSpaceError: 4,
            maximumMemoryUsage: 256,
            dynamicScreenSpaceError: true,
            skipLevelOfDetail: true,
            preferLeaves: true,
          })
        );
        await cesiumTileset.readyPromise;
        const heightOffset = -325.0;
        const boundingSphere = cesiumTileset.boundingSphere;
        const cartographic = Cartographic.fromCartesian(boundingSphere.center);
        const surface = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          0.0
        );
        const offset = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          heightOffset
        );
        const translation = Cartesian3.subtract(
          offset,
          surface,
          new Cartesian3()
        );
        cesiumTileset.modelMatrix = Matrix4.fromTranslation(translation);
        return cesiumTileset;
      })
    );
  }, [object3dState.baseObjList, viewer?.scene.primitives]);

  useEffect(() => {
    if (viewer && mapType === '2D') {
      if (tilesetRef.current) {
        tilesetRef.current.forEach((tile) => {
          viewer.scene.primitives.remove(tile);
        });
      }
      viewer.scene.requestRender();
      flyToPoint(
        [CampusCoords.longitude - 0.003, CampusCoords.latitude],
        2000.0
      );
    } else if (object3dState.baseObjList && viewer && mapType === '3D') {
      dispatch(setMapLoading(true));
      flyToPoint(
        [CampusCoords.longitude - 0.003, CampusCoords.latitude - 0.004],
        1000.0
      );
      getTileset().then((newTileset) => {
        dispatch(setMapLoading(false));
        tilesetRef.current = newTileset;
      });
    }
  }, [
    mapType,
    object3dState.baseObjList,
    viewer,
    flyToPoint,
    dispatch,
    getTileset,
  ]);

  useEffect(() => {
    if (viewer) {
      viewer.camera.moveEnd.addEventListener(function () {
        if (viewer) {
          const scratchRectangle = new Rectangle();
          const rect = viewer.camera.computeViewRectangle(
            viewer.scene.globe.ellipsoid,
            scratchRectangle
          );
          if (rect) {
            const west = Number(CesiumMath.toDegrees(rect.west).toFixed(4));
            const south = Number(CesiumMath.toDegrees(rect.south).toFixed(4));
            const east = Number(CesiumMath.toDegrees(rect.east).toFixed(4));
            const north = Number(CesiumMath.toDegrees(rect.north).toFixed(4));
            west &&
              south &&
              east &&
              north &&
              setMapBBOX({
                west: west,
                south: south,
                east: east,
                north: north,
              });
          }
        }
      });
      viewer.scene.requestRenderMode = true;
    }
  }, [viewer]);

  const createBoundaryPoisUrl = useCallback((): URLSearchParams => {
    const params = new URLSearchParams();
    params.append('SERVICE', 'WMS');
    params.append('VERSION', '1.1.1');
    params.append('REQUEST', 'GetMap');
    params.append('FORMAT', 'geojson');
    params.append('TRANSPARENT', 'true');
    params.append('exceptions', 'application%2Fvnd.ogc.se_inimage');
    params.append('SRS', 'EPSG:4326');
    params.append('WIDTH', String(window.innerWidth));
    params.append('HEIGHT', String(window.innerHeight));
    params.append(
      'BBOX',
      mapBBOX?.west +
        ',' +
        mapBBOX?.south +
        ',' +
        mapBBOX?.east +
        ',' +
        mapBBOX?.north
    );
    const layerIds = MapUtils.createLayerIdArray(
      mapFilter.layerIds,
      mapFilter.selectedGroups
    );
    params.append('LAYERS', 'czu:mgnl_poi');
    const cqlFilter = MapUtils.createCQLFilterUrl(
      mapFilter.selectedCategoryId,
      layerIds.length === 0
        ? defaultEvent?.layers
          ? defaultEvent.layers.map((id) => id as string)
          : []
        : layerIds
    );
    cqlFilter && params.append('CQL_FILTER', 'visible=true and ' + cqlFilter);
    !cqlFilter && params.append('CQL_FILTER', 'visible=true');
    return params;
  }, [
    mapBBOX?.west,
    mapBBOX?.south,
    mapBBOX?.east,
    mapBBOX?.north,
    mapFilter.layerIds,
    mapFilter.selectedGroups,
    mapFilter.selectedCategoryId,
    defaultEvent?.layers,
  ]);

  // TO DO: marker list optimisation
  // const addFeaturesToMarkers = useCallback(
  //   (features: Feature<Point>[]) => {
  //     const markers = MapUtils.createMarkersFromFeatures(features);
  //     const newMarkers: Feature<Point>[] = [];
  //     if (markersList?.features && markersList.features.length > 0) {
  //       markers.features.forEach((feature) => {
  //         let poiExist = false;
  //         markersList.features.forEach((poiEntity) => {
  //           if (poiEntity && poiEntity.id === feature.id) {
  //             poiExist = true;
  //           }
  //         });
  //         !poiExist && newMarkers.push(feature);
  //       });
  //     }
  //     if (newMarkers.length > 0) {
  //       markers.features = newMarkers;
  //     } else {
  //       markers.features = [];
  //     }
  //     return markers;
  //   },
  //   [markersList?.features]
  // );
  //
  // const setNewMarkerList = useCallback(
  //   (features: Feature<Point>[], newList?: boolean) => {
  //     const newMarkers = addFeaturesToMarkers(features);
  //     if (newList) {
  //       setMarkersList(MapUtils.createMarkersFromFeatures(features));
  //     } else {
  //       newMarkers.features.length > 0
  //         ? setMarkersList((oldMarkers) =>
  //             oldMarkers
  //               ? ({
  //                   ...oldMarkers,
  //                   features: oldMarkers.features.concat(newMarkers.features),
  //                 } as FeatureCollection<Point>)
  //               : newMarkers
  //           )
  //         : setMarkersList(MapUtils.createMarkersFromFeatures(features));
  //     }
  //   },
  //   [addFeaturesToMarkers]
  // );

  useEffect(() => {
    let cancelled = false;
    if (
      mapBBOX &&
      !(
        objectPreviewView.openObjectPreview &&
        objectPreview.objectMobileScene === 'route'
      )
    ) {
      const layerIdList = MapUtils.getLayerIdsFromGroup(
        mapFilter.selectedGroups
      );
      if (!(mapFilter.selectedGroups.length > 0 && layerIdList.length === 0)) {
        axios
          .get(config.mapsWmsUrl, {
            params: createBoundaryPoisUrl(),
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 10000,
          })
          .then((response) => {
            if (
              !cancelled &&
              response.data &&
              response.data.features &&
              response.data.features.length > 0
            ) {
              dispatch(setPoiMarkers(response.data.features));
              setTimeout(() => {
                viewer?.scene.requestRender();
              }, 1);
            } else {
              dispatch(setPoiMarkers([]));
              viewer?.scene.requestRender();
            }
          })
          .catch((ex) => {
            // console.log(ex);
          });
      } else {
        dispatch(setPoiMarkers([]));
        viewer?.scene.requestRender();
      }
    }
    return () => {
      cancelled = true;
    };
  }, [
    mapBBOX,
    createBoundaryPoisUrl,
    objectPreviewView.openObjectPreview,
    mapFilter.selectedCategoryId,
    mapFilter.selectedGroups,
    objectPreview.objectMobileScene,
    viewer?.scene,
    dispatch,
  ]);

  const removeSelectedPoi = useCallback(() => {
    selectedPoi?.current?.entities && selectedPoi.current.entities.removeAll();
    viewer?.scene.requestRender();
  }, [viewer?.scene]);

  const highlightSelectPoi = useCallback(
    (lon: number, lat: number) => {
      if (viewer) {
        const position = selectedPoi.current?.entities
          .getById('SelectedDot')
          ?.position?.getValue(viewer.clock.currentTime);
        const cord = position && Cartographic.fromCartesian(position);
        const oldLat = cord && CesiumMath.toDegrees(cord.latitude).toFixed(4);
        const oldLon = cord && CesiumMath.toDegrees(cord.longitude).toFixed(4);
        if (oldLat !== lat.toFixed(4) && oldLon !== lon.toFixed(4)) {
          removeSelectedPoi();
          const poiSelectionDataSource = new CustomDataSource('poiSelection');
          poiSelectionDataSource.entities.add({
            id: 'SelectedDot',
            position: Cartesian3.fromDegrees(lon, lat),
            billboard: {
              image: '/assets/icon/DotFinal.svg',
              scale: 0.08,
              heightReference: 1,
            },
          });
          selectedPoi.current = poiSelectionDataSource;
          flyToPoint([Number(lon) - 0.003, Number(lat)], 600.0);
          viewer && viewer.dataSources.add(poiSelectionDataSource);
        }
      }
    },
    [flyToPoint, removeSelectedPoi, viewer]
  );

  useEffect(() => {
    if (
      objectPreviewView.openObjectPreview &&
      viewer &&
      objectPreview.objectMobileScene === 'poi' &&
      (objectPreview.objectPreviewData as Poi).coordinates
    ) {
      const lon = (objectPreview.objectPreviewData as Poi).coordinates
        ?.longitude;
      const lat = (objectPreview.objectPreviewData as Poi).coordinates
        ?.latitude;
      lon && lat && highlightSelectPoi(Number(lon), Number(lat));
    } else {
      removeSelectedPoi();
    }
  }, [
    highlightSelectPoi,
    objectPreviewView.openObjectPreview,
    objectPreview.objectMobileScene,
    objectPreview.objectPreviewData,
    viewer,
    removeSelectedPoi,
  ]);

  const addNewRoute = useCallback(
    (positions: Cartesian3[]) => {
      if (viewer) {
        const routeDataSource = new CustomDataSource('route');
        routeDataSource.entities.add({
          id: 'startRoute',
          name: 'startRoute',
          position: positions[0],
          point: {
            pixelSize: 20,
            color: Color.fromBytes(118, 255, 94),
          },
        });
        routeDataSource.entities.add({
          id: 'endRoute',
          name: 'endRoute',
          position: positions[positions.length - 1],
          point: {
            pixelSize: 15,
            color: Color.RED,
          },
        });
        routeDataSource.entities.add({
          id: 'directionRoute',
          name: 'directionRoute',
          polyline: {
            positions: positions,
            width: 7,
            material: new PolylineDashMaterialProperty({
              color: Color.DEEPSKYBLUE,
              dashLength: 10,
            }),
            clampToGround: true,
          },
        });
        setRouteEntities(routeDataSource);
      }
    },
    [viewer]
  );

  const getRouteReq = useCallback(
    (points: Coordinates[], route: Route) => {
      if (points && points.length > 0) {
        const ghRouting = new GraphHopperRouting({
          vehicle: 'foot',
          locale: 'cs',
          geometries: 'geometries',
          elevation: false,
          points_encoded: false,
          host: config.navigationServiceUrl,
          'ch.disable': true,
        });
        points.forEach((point) => {
          ghRouting.addPoint(new GHInput(point.latitude, point.longitude));
        });
        getGraphhopperRoute({
          routePoints: points,
          vehicle: VehicleType.FOOT,
          locale: 'cs',
          host: config.navigationServiceUrl as string,
        })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            const bbox = response.paths[0].bbox;
            bbox &&
              viewer?.camera.flyTo({
                destination: Rectangle.fromDegrees(
                  bbox[0] - 0.0022,
                  bbox[1] - 0.0003,
                  bbox[2] + 0.0011,
                  bbox[3] + 0.0003
                ),
              });
            if (response.paths[0].points.coordinates && viewer) {
              const coordinates = response.paths[0].points.coordinates;
              const polylineToDisplay =
                MapUtils.flatenArrayOfArray(coordinates);
              const polylinePoints = Cartesian3.fromDegreesArray(
                polylineToDisplay as number[]
              );
              polylinePoints && addNewRoute(polylinePoints);
              route?.pois &&
                dispatch(
                  setMarkersList(MapUtils.createMarkersFromRoute(route))
                );
              viewer?.scene.requestRender();
            }
          })
          .catch((ex: Error) => {
            // console.log(ex);
          });
      }
    },
    [addNewRoute, dispatch, viewer]
  );

  const removeRoute = useCallback(() => {
    if (viewer) {
      const source = viewer.dataSources.getByName('route');
      if (source[0]) {
        viewer.dataSources.remove(source[0]);
        viewer.scene.requestRender();
      }
    }
  }, [viewer]);

  useEffect(() => {
    if (
      objectPreviewView.openObjectPreview &&
      objectPreview.objectMobileScene === 'route' &&
      (objectPreview.objectPreviewData as Route).pois
    ) {
      const coordsList: Coordinates[] = [];
      (objectPreview.objectPreviewData as Route).pois?.forEach((poi) => {
        if (
          poi.coordinates &&
          poi.coordinates.latitude &&
          poi.coordinates.longitude
        ) {
          coordsList.push(poi.coordinates);
        }
      });
      if (viewer && coordsList && coordsList.length === 1) {
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(
            Number(coordsList[0].longitude),
            Number(coordsList[0].latitude),
            1000.0
          ),
        });
      } else {
        coordsList &&
          getRouteReq(coordsList, objectPreview.objectPreviewData as Route);
      }
    } else if (
      (viewer && !objectPreviewView.openObjectPreview) ||
      (objectPreviewView.openObjectPreview &&
        objectPreview.objectMobileScene !== 'route')
    ) {
      removeRoute();
    }
  }, [
    objectPreview.objectMobileScene,
    objectPreviewView.openObjectPreview,
    objectPreview.objectPreviewData,
    viewer,
    getRouteReq,
    removeRoute,
  ]);

  const handleSelectPoi = useCallback(
    (id: string) => {
      if (
        objectPreviewView.openObjectPreview &&
        objectPreview.objectMobileScene === 'route'
      ) {
        removeRoute();
      }
      navigateTo(`/map/poi/${id}`);
    },
    [
      navigateTo,
      objectPreview.objectMobileScene,
      objectPreviewView.openObjectPreview,
      removeRoute,
    ]
  );

  const getPoiCategoriesPinBuilderIcons = useCallback(
    async (categories: PoiCategory[]): Promise<CategoryPinBuilderIcon[]> => {
      const mapCategoryIcons: CategoryPinBuilderIcon[] = [];
      for (const category of categories) {
        const icon = await MapUtils.getCategoryPinIcon(category);
        icon && mapCategoryIcons.push({category, icon});
      }
      return mapCategoryIcons;
    },
    []
  );

  useEffect(() => {
    if (poiCategoryState.baseObjList) {
      getPoiCategoriesPinBuilderIcons(poiCategoryState.baseObjList).then(
        (poiCategoryPinBuilderIcons) =>
          setCategoryPinBuilderIcons(poiCategoryPinBuilderIcons)
      );
    }
  }, [getPoiCategoriesPinBuilderIcons, poiCategoryState.baseObjList]);

  const getCategoryPinFromCategory = useCallback(
    (categoryId: string): HTMLCanvasElement | undefined => {
      if (categoryPinBuilderIcons) {
        return categoryPinBuilderIcons.find(
          (categoryPinBuilderIcon) =>
            categoryPinBuilderIcon.category?.id === categoryId
        )?.icon;
      }
      return undefined;
    },
    [categoryPinBuilderIcons]
  );

  useEffect(() => {
    if (viewer && routeEntities) {
      const source = viewer.dataSources.getByName('route');
      source[0] && viewer.dataSources.remove(source[0]);
      viewer.dataSources
        .add(routeEntities)
        .then(() => viewer.scene.requestRender());
    }
  }, [routeEntities, viewer]);

  const showEntities = useMemo(() => {
    if (mapLoaded && categoryPinBuilderIcons.length > 0) {
      return (
        <>
          {markersList?.features.map((feature: Feature<Point>, index) => {
            markersList?.features.length - 1 === index &&
              viewer?.scene.requestRender();
            return (
              <Entity
                ref={(el) =>
                  feature.id && (mapEntityRef.current[feature.id] = el)
                }
                id={'map_poi_' + feature.id}
                key={feature.id}
                position={Cartesian3.fromDegrees(
                  feature.geometry.coordinates[0],
                  feature.geometry.coordinates[1],
                  0
                )}
                onClick={() =>
                  feature.id && handleSelectPoi(String(feature.id))
                }>
                <BillboardGraphics
                  show
                  image={getCategoryPinFromCategory(
                    feature.properties?.category_id
                  )?.toDataURL('image/png')}
                  width={22}
                  height={30}
                  verticalOrigin={1}
                  heightReference={1}
                />
              </Entity>
            );
          })}
        </>
      );
    }
  }, [
    mapLoaded,
    categoryPinBuilderIcons.length,
    markersList?.features,
    getCategoryPinFromCategory,
    viewer?.scene,
    handleSelectPoi,
  ]);

  const renderMap = useMemo(() => {
    return (
      <Viewer
        id={'cesiumContainer'}
        className={classes.map}
        baseLayerPicker={false}
        homeButton={false}
        navigationHelpButton={false}
        fullscreenButton={false}
        infoBox={false}
        projectionPicker={false}
        scene3DOnly={true}
        timeline={false}
        navigationInstructionsInitiallyVisible={false}
        animation={false}
        sceneModePicker={false}
        imageryProvider={false}
        geocoder={false}
        selectionIndicator={false}
        ref={cesiumRef}>
        <ImageryLayer
          key={imageryProviderUrl}
          imageryProvider={defaultImageryProvider}
        />
        <ResiumCustomDataSource>{showEntities}</ResiumCustomDataSource>
      </Viewer>
    );
  }, [classes.map, defaultImageryProvider, showEntities]);

  return (
    <>
      <MapLoadingIndicator showLoading={isMapLoading} />
      <MapLoadingIndicator showLoading={!mapLoaded} />
      {renderMap}
    </>
  );
};
