const config = {
  mapsWmsUrl:
    window._env_?.REACT_APP_MAPS_WMS_URL || 'https://maps.demonow.eu/czu/wms',
  osmWmsUrl:
    window._env_?.REACT_APP_OSM_WMS_URL || 'http://maps.demonow.eu/osm/wms',
  cmsRestUrl:
    window._env_?.REACT_APP_CMS_REST_URL ||
    'https://api.demonow.eu/cms-service/.rest',
  navigationServiceUrl:
    window._env_?.REACT_APP_NAVIGATION_SERVICE_URL ||
    'https://api.demonow.eu/navigation-service',
  czuRelayRestUrl:
    window._env_?.REACT_APP_CZU_RELAY_REST_URL ||
    'https://api.demonow.eu/czu-api-cache-service/v1',
  userDataRestUrl:
    window._env_?.REACT_APP_USER_DATA_REST_URL ||
    'https://api.demonow.eu/user-data-service/v1',
  globalPoi360ID:
    window._env_?.GLOBAL_POI_360 || 'f7252174-f984-4e17-91e5-9304d2f039fc',
  appVersion: window._env_?.APP_VERSION || '1.0.0',
  environmentName: window._env_?.ENVIRONMENT_NAME || 'Dev',
  environmentUrl: window._env_?.ENVIRONMENT_URL || 'demonow.eu',
  dynamicLinkUrl:
    window._env_?.DYNAMIC_LINK_URL || 'https://czu.page.link/events',
};
export default config;
