import React, {MouseEventHandler} from 'react';
import {Box, Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AddCircleRounded} from '@mui/icons-material';

export interface ButtonAddProps {
  id?: string;
  name: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
}

export const ButtonAdd = ({
  id,
  name,
  onClick,
  icon,
  color,
}: ButtonAddProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    buttonContainer: {
      textAlign: 'center',
    },
    button: {
      borderRadius: 15,
      backgroundColor: color ? color : theme.palette.primary.main,
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.buttonContainer}>
      <Button
        id={id}
        type="submit"
        variant="contained"
        color="primary"
        classes={{root: classes.button}}
        onClick={onClick}
        startIcon={icon ? icon : <AddCircleRounded />}>
        {name}
      </Button>
    </Box>
  );
};
