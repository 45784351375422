import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../store';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event/api';
import {getFilterGroupsList} from './data-reducers/GroupsSlice';
import {Feature, FeatureCollection, Point} from 'geojson';
import {Poi} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {MapUtils} from '@skczu/czu-frontend-library';

export interface MapPoiFilter {
  selectedGroups: EventFilterRefObject[];
  layerIds: string[];
  selectedCategoryId: string | null;
}

interface MapState {
  isFilter?: boolean;
  mapFilter: MapPoiFilter;
  mapType: '2D' | '3D';
  markersList?: FeatureCollection<Point>;
}

const initialState: MapState = {
  mapFilter: {
    selectedGroups: [],
    layerIds: [],
    selectedCategoryId: null,
  },
  mapType: '2D',
  isFilter: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setFilter: (state, {payload}: PayloadAction<boolean>) => {
      state.isFilter = payload;
    },
    setFilterLayerIds: (state, {payload}: PayloadAction<string>) => {
      state.mapFilter.selectedGroups = [];
      state.mapFilter.layerIds =
        state.mapFilter.layerIds.indexOf(payload) > -1
          ? state.mapFilter.layerIds.filter((id) => payload !== id)
          : [...state.mapFilter.layerIds, payload];
    },
    setFilterSelectedGroup: (
      state,
      {payload}: PayloadAction<EventFilterRefObject>
    ) => {
      state.mapFilter.layerIds = [];
      state.mapFilter.selectedGroups =
        state.mapFilter.selectedGroups
          .map((selectedGroup) => selectedGroup.id)
          .indexOf(payload.id) > -1
          ? state.mapFilter.selectedGroups.filter(
              (group) => payload.id !== group.id
            )
          : [...state.mapFilter.selectedGroups, payload];
    },
    setSelectedGroups: (
      state,
      {payload}: PayloadAction<EventFilterRefObject[]>
    ) => {
      state.mapFilter.selectedGroups = payload;
    },
    setFilterCategoryId: (state, {payload}: PayloadAction<string>) => {
      state.mapFilter.selectedCategoryId =
        state.mapFilter.selectedCategoryId === payload ? '' : payload;
    },
    setMapType: (state, {payload}: PayloadAction<'2D' | '3D'>) => {
      state.mapType = payload;
    },
    setMarkersList: (
      state,
      {payload}: PayloadAction<FeatureCollection<Point> | undefined>
    ) => {
      state.markersList = payload;
    },
  },
});

export const setSelectedMapGroup =
  (groupId: string): AppThunk =>
  async (dispatch, getState) => {
    const baseObjList = getState().groupsList.filterDataList;
    if (!baseObjList || baseObjList.length === 0) {
      dispatch(
        getFilterGroupsList(true, undefined, () =>
          dispatch(setSelectedMapGroupById(groupId))
        )
      );
    } else {
      dispatch(setSelectedMapGroupById(groupId));
    }
  };

export const setSelectedMapGroupById =
  (groupId: string): AppThunk =>
  async (dispatch, getState) => {
    const baseObjList = getState().groupsList.filterDataList;

    const foundGroup = baseObjList?.find((group) => group?.id === groupId);
    if (foundGroup) {
      let newUserGroups: EventFilterRefObject[] = [];
      const selectedGroups = getState().map.mapFilter.selectedGroups;
      if (selectedGroups) {
        newUserGroups =
          selectedGroups
            .map((selectedGroup: EventFilterRefObject) => selectedGroup.id)
            .indexOf(foundGroup.id) > -1
            ? selectedGroups.filter(
                (savedGroup: EventFilterRefObject) =>
                  savedGroup.id !== foundGroup.id
              )
            : [...selectedGroups, foundGroup];
      }
      dispatch(setSelectedGroups(newUserGroups));
    }
  };

export const setPoiMarkers =
  (features: Feature<Point>[]): AppThunk =>
  async (dispatch, getState) => {
    const objectPreviewView = getState().drawer.objectPreviewView;
    const objectPreview = getState().drawer.objectPreview;
    const isSelectedPoi = !!(
      objectPreviewView.openObjectPreview &&
      objectPreview.objectMobileScene === 'poi' &&
      (objectPreview.objectPreviewData as Poi).coordinates?.longitude &&
      (objectPreview.objectPreviewData as Poi).coordinates?.latitude
    );
    const featuresList =
      isSelectedPoi &&
      !features.some(
        (feature: Feature<Point>) =>
          feature.properties?.mgnl_id ===
          (objectPreview.objectPreviewData as Poi).id
      )
        ? [
            ...MapUtils.createMarkersFromFeatures(features).features,
            isSelectedPoi &&
              MapUtils.createMarkerFromPoi(
                objectPreview.objectPreviewData as Poi
              ),
          ]
        : MapUtils.createMarkersFromFeatures(features).features;
    dispatch(
      setMarkersList({
        ...MapUtils.createMarkersFromFeatures(features),
        features: featuresList,
      })
    );
  };

export const {
  setFilter,
  setFilterCategoryId,
  setFilterSelectedGroup,
  setMapType,
  setSelectedGroups,
  setMarkersList,
} = mapSlice.actions;

export const selectMap = (state: RootState): typeof state.map => state.map;
export const selectMapType = (state: RootState) => state.map.mapType;
export const selectMapFilter = (state: RootState) => {
  const {mapFilter, isFilter} = state.map;
  return {mapFilter, isFilter};
};

export default mapSlice.reducer;
