import React, {useCallback, useEffect, useMemo, useState} from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Event, FiberNew, Public, SwapHoriz} from '@mui/icons-material';
import Menu from '@mui/icons-material/Menu';
import {
  useHistory,
  useRouteMatch,
  Route as RouteDom,
  Switch,
} from 'react-router-dom';
import {styled, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {
  clearObjectPreview,
  selectDrawer,
} from '../../../../redux/reducers/DrawerSlice';
import {useTranslation} from 'react-i18next';
import {ListOfRoutesTab} from './menu-lists/routes/ListOfRoutesTab';
import {ListOfPoisTab} from './menu-lists/poi/ListOfPoisTab';
import {ListOfNewsTab} from './menu-lists/news/ListOfNewsTab';
import {showImagePreview} from '../../../../redux/reducers/ImagePreview';
import i18n from 'i18next';
import {
  AboutApp,
  SearchInput,
  AppLanguage,
  TooltipIconButton,
  DrawerMenu,
  MobileScreenPreview,
} from '@skczu/czu-react-components';
import {
  IconMenuItem,
  ImagePreviewImage,
  Language,
  PoiWithFoodMenu,
} from '@skczu/czu-frontend-library';
import {CustomUtils} from '../../../shared/utils/CustomUtils';
import config from '../../../../config';
import {ListOfEventsTab} from './menu-lists/events/ListOfEventsTab';
import {
  foodMenuSelector,
  getAllergens,
  getRestaurantDailyMenu,
} from '../../../../redux/reducers/data-reducers/FoodMenuSlice';
import moment from 'moment/moment';
import {FeedbackDialog} from '../../../shared/dialogs/feedback/FeedbackDialog';
import {WishlistMenuItem} from './item/WishlistMenuItem';
import {Box, ListItemButton, useTheme} from '@mui/material';

export const DrawerMenuWidth = 350;
export const DrawerWidth = 150;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  width: DrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const MenuItemsArr: IconMenuItem[] = [
  {name: 'menu', icon: <Menu />},
  {name: 'poi', icon: <Public />},
  {name: 'routes', icon: <SwapHoriz />},
  {name: 'news', icon: <FiberNew />},
  {name: 'events', icon: <Event />},
];
export const LeftDrawer = (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openMenuList, setOpenMenuList] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | ''>('');
  const dispatch = useAppDispatch();
  const {objectPreview, objectPreviewView} = useAppSelector(selectDrawer);
  const {allergens} = useAppSelector(foodMenuSelector);
  const navigateTo = useCallback((newPath) => history.push(newPath), [history]);
  const theme = useTheme();
  const styles = {
    root: {display: 'flex'},
    drawer: {
      width: DrawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
    },
    appLanguage: {
      alignSelf: 'flex-end',
    },
    drawerFoot: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      width: '100%',
    },
    drawerDisabled: {
      background: theme.palette.grey.A200,
    },
    drawerDisabledColor: {
      color: theme.palette.grey.A100,
    },
    drawerEnabledColor: {
      color: theme.palette.secondary.main,
    },
    drawerEnabled: {
      background: theme.palette.primary.dark,
    },
    drawerOpen: {
      background: theme.palette.primary.dark,
    },
    itemText: {
      backgroundColor: theme.palette.secondary.light + '!important',
    },
    objectPreviewOpen: {
      position: 'absolute',
      left: DrawerMenuWidth + DrawerWidth,
      top: '12.5%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.secondary.main,
      zIndex: 10,
    },
    objectPreviewClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    searchInputOpen: {
      zIndex: 100,
      position: 'absolute',
      left: DrawerWidth + 20,
      top: '2%',
    },
    searchInputClosed: {
      zIndex: 100,
      position: 'absolute',
      left: DrawerWidth - 80,
      top: '2%',
    },
  };
  const handleNavigation = useCallback(
    (newPath: string) => history.replace(`${newPath}`),
    [history]
  );

  const handleMapNavigation = useCallback(
    (item) => history.push(`${url}/${item}`),
    [history, url]
  );

  useEffect(() => {
    if (history.location.pathname) {
      const routeName = history.location.pathname.toLowerCase().split('/');
      let lastRouteName = routeName[routeName.length - 1];
      if (
        lastRouteName !== 'map' &&
        MenuItemsArr.some((item) => item.name === lastRouteName)
      ) {
        setSelectedItem(lastRouteName);
        setOpenDrawer(true);
        setOpenMenuList(true);
      } else {
        lastRouteName = routeName[routeName.length - 2];
        if (
          lastRouteName !== 'map' &&
          MenuItemsArr.some((item) => item.name === lastRouteName)
        ) {
          setSelectedItem(lastRouteName);
          setOpenDrawer(true);
          setOpenMenuList(true);
        }
      }
      if (!lastRouteName) {
        setSelectedItem('');
        setOpenMenuList(false);
      }
    }
  }, [history.location.pathname]);

  const handleDrawerOpen = useCallback(
    (item: IconMenuItem) => {
      if (item.name === selectedItem) {
        setOpenDrawer((openMenu) => !(openMenu && item.name === 'menu'));
        item.name !== 'menu' && handleDrawerClose();
        const routeName = history.location.pathname.toLowerCase().split('/');
        if (routeName[routeName.length - 1] !== 'map') {
          item.name !== 'menu' &&
            handleNavigation(
              history.location.pathname.substring(
                0,
                history.location.pathname.lastIndexOf('/')
              )
            );
        }
      } else {
        item.name !== 'menu' && handleMapNavigation(item.name);
        setOpenMenuList(item.name !== 'menu');
        setOpenDrawer((openMenu) => !(openMenu && item.name === 'menu'));
        setSelectedItem(item.name);
        const routeName = history.location.pathname.toLowerCase().split('/');
        if (routeName[routeName.length - 1] !== 'map') {
          item.name === 'menu' &&
            handleNavigation(
              history.location.pathname.substring(
                0,
                history.location.pathname.lastIndexOf('/')
              )
            );
        }
      }
      dispatch(clearObjectPreview());
    },
    [
      dispatch,
      handleMapNavigation,
      handleNavigation,
      history.location.pathname,
      selectedItem,
    ]
  );

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedItem('');
    setOpenMenuList(false);
  };

  const selectImagePreview = useCallback(
    (selectedImage: ImagePreviewImage, images: ImagePreviewImage[]) => {
      dispatch(showImagePreview({selectedImage, images}));
    },
    [dispatch]
  );

  const onPressBack = useCallback(() => {
    handleNavigation(
      history.location.pathname.substring(
        0,
        history.location.pathname.lastIndexOf('/')
      )
    );
    dispatch(clearObjectPreview());
  }, [dispatch, handleNavigation, history.location.pathname]);

  const handleChangeLanguage = async (language: Language) => {
    await i18n.changeLanguage(language);
  };

  useEffect(() => {
    dispatch(getAllergens());
  }, [dispatch]);

  const onDailyMenuDateChanged = useCallback(
    (date: string, newWeek: boolean) => {
      const foodPointId = (objectPreview.objectPreviewData as PoiWithFoodMenu)
        .foodPointId;
      foodPointId &&
        dispatch(
          getRestaurantDailyMenu(
            foodPointId,
            moment(date).format('YYYY-MM-DD'),
            newWeek
          )
        );
    },
    [dispatch, objectPreview.objectPreviewData]
  );

  const renderMobileScreenPreview = useMemo(() => {
    return (
      <Box
        sx={
          objectPreviewView.openObjectPreview
            ? styles.objectPreviewOpen
            : styles.objectPreviewClose
        }>
        {objectPreviewView.openObjectPreview &&
          objectPreview.objectPreviewData && (
            <MobileScreenPreview
              t={t}
              objectMobileScene={objectPreview.objectMobileScene}
              getObjByLanguage={CustomUtils.getObjByLanguage}
              objectPreviewData={CustomUtils.getObjByLanguage(
                objectPreview.objectPreviewData
              )}
              allergens={allergens}
              onClickBack={onPressBack}
              onGoToPoi360={(poi360Id) =>
                history.push(`/map/panorama/${poi360Id}`)
              }
              onGoToPoi={(poiId) => history.push(`/map/poi/${poiId}`)}
              onGoToRoute={(routeId) => history.push(`/map/routes/${routeId}`)}
              imagePreview={selectImagePreview}
              onDailyMenuDateChanged={onDailyMenuDateChanged}
            />
          )}
      </Box>
    );
  }, [
    allergens,
    history,
    objectPreview.objectMobileScene,
    objectPreview.objectPreviewData,
    objectPreviewView.openObjectPreview,
    onDailyMenuDateChanged,
    onPressBack,
    selectImagePreview,
    styles.objectPreviewClose,
    styles.objectPreviewOpen,
    t,
  ]);

  return (
    <Box sx={styles.root}>
      <Drawer
        open={openDrawer}
        variant="permanent"
        PaperProps={{
          sx: styles.drawerOpen,
        }}>
        <List>
          {MenuItemsArr.map((item, index) => (
            <Box key={item.name + '_' + index}>
              <ListItemButton
                key={item.name}
                selected={selectedItem === item.name && item.name !== 'menu'}
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => handleDrawerOpen(item)}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 1 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <TooltipIconButton
                    instance={item}
                    icon={item.icon}
                    label={t(`leftMenu.${item.name}`)}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    opacity: openDrawer ? 1 : 0,
                    color: theme.palette.secondary.main,
                  }}
                  primary={t(`leftMenu.${item.name}`)}
                />
              </ListItemButton>
              <Divider />
            </Box>
          ))}
        </List>
        <Box sx={styles.drawerFoot}>
          <>
            <Box sx={styles.appLanguage}>
              <AppLanguage
                shown={openDrawer}
                onChange={handleChangeLanguage}
                language={i18n.language as Language}
              />
            </Box>
            <WishlistMenuItem open={openDrawer} />
            <FeedbackDialog open={openDrawer} />
            <AboutApp
              label={t('allObjects.About app')}
              open={openDrawer}
              appName={'My ČZU'}
              appVersion={config?.appVersion}
              envName={
                config?.environmentName !== 'Prod'
                  ? config?.environmentName
                  : ''
              }
              appLogo={'/assets/logo/CZULogoPNG.png'}
              appDialogLogo={'/assets/logo/CZULogoColorPNG.png'}
            />
          </>
        </Box>
      </Drawer>
      <Box sx={openDrawer ? styles.searchInputOpen : styles.searchInputClosed}>
        <SearchInput
          placeholder={t('leftMenu.search')}
          name={'route-search'}
          value={''}
          onInputClick={() => navigateTo('/map/poi')}
        />
      </Box>
      <Switch>
        <DrawerMenu
          open={openMenuList}
          drawerWidth={DrawerWidth}
          drawerMenuWidth={DrawerMenuWidth}>
          {openMenuList ? (
            <>
              <RouteDom path={`${path}/poi/:poiId?`}>
                <ListOfPoisTab />
              </RouteDom>
              <RouteDom path={`${path}/routes/:routeId?`}>
                <ListOfRoutesTab />
              </RouteDom>
              <RouteDom path={`${path}/news/:newsId?`}>
                <ListOfNewsTab />
              </RouteDom>
              <RouteDom path={`${path}/events/:eventId?`}>
                <ListOfEventsTab />
              </RouteDom>
            </>
          ) : null}
        </DrawerMenu>
      </Switch>
      <RouteDom
        path={[
          `${path}/poi`,
          `${path}/routes`,
          `${path}/news`,
          `${path}/events`,
        ]}>
        {renderMobileScreenPreview}
      </RouteDom>
    </Box>
  );
};
