import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LeftDrawer} from './left-panel-menu/DrawerLeft';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {MapFilter} from './filter/MapFilter';
import {MenuBottom} from './menu-bottom/MenuBottom';
import {Route, useRouteMatch} from 'react-router-dom';
import {PanoramaPage} from '../panorama-scene/PanoramaPage';
import {MapComponent} from './map/MapComponent';
import {
  getEventCategoriesList,
  getFilterPoiCategoryList,
  getPoiCategoryList,
} from '../../../redux/reducers/data-reducers/PoiCategoriesSlice';
import {getBasePoiList} from '../../../redux/reducers/data-reducers/PoiSlice';
import {getBaseRouteList} from '../../../redux/reducers/data-reducers/RouteSlice';
import {getBaseNewsList} from '../../../redux/reducers/data-reducers/NewsSlice';
import {
  getBaseGroupsList,
  getDefaultEvent,
  getFilterGroupsList,
} from '../../../redux/reducers/data-reducers/GroupsSlice';
import {
  changeSelectedImage,
  closeImagePreview,
  imagePreviewSelector,
} from '../../../redux/reducers/ImagePreview';
import {ImgPreview} from '@skczu/czu-react-components';
import {getTileset3dUrlList} from '../../../redux/reducers/data-reducers/Object3dSlice';

const useStyles = makeStyles(() => ({
  routeContainer: {
    position: 'absolute',
    left: '60%',
    top: 55,
    transform: 'translate(-50%, -50%)',
    width: '50%',
    zIndex: 2,
  },
  bottomMenu: {
    position: 'absolute',
    left: '45%',
    bottom: '2%',
  },
}));

export const MapPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();
  const {preview, imagePreview} = useAppSelector(imagePreviewSelector);

  useEffect(() => {
    dispatch(getPoiCategoryList());
    dispatch(getTileset3dUrlList());
    dispatch(getBasePoiList(true));
    dispatch(getBaseRouteList(true));
    dispatch(getBaseNewsList(true));
    dispatch(getBaseGroupsList(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterPoiCategoryList(true));
    dispatch(
      getFilterGroupsList(true, undefined, () =>
        dispatch(getEventCategoriesList())
      )
    );
    dispatch(getDefaultEvent());
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column">
      <Route path={`${path}/panorama/:poi360Id?`}>
        <Box style={{zIndex: 4}}>
          <PanoramaPage />
        </Box>
      </Route>
      <Box
        style={{
          zIndex: 3,
          position: 'absolute',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}>
        <Box style={{zIndex: 2}}>
          <LeftDrawer />
        </Box>
        <Box style={{zIndex: 1}}>
          <MapComponent />
          <Box className={classes.bottomMenu}>
            <MenuBottom />
          </Box>
          <MapFilter />
        </Box>
      </Box>
      <ImgPreview
        onCloseDialog={() => dispatch(closeImagePreview())}
        preview={preview}
        imagePreview={imagePreview}
        onChangeSelected={(index) => {
          dispatch(changeSelectedImage(index));
        }}
      />
    </Box>
  );
};
