import {Box, InputBase, styled, Typography} from '@mui/material';
import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BasicDialog} from '@skczu/czu-react-components';
import {FeedbackEmoticonSelection} from './FeedbackEmoticonSelection';
import {useAppSelector} from '../../../../hooks/hooks';
import {
  feedbackSelector,
  sendFeedback,
  setFeedbackChecked,
  setFeedbackDialogOpen,
  setFeedbackEmoticon,
  setFeedbackText,
} from '../../../../redux/reducers/FeedbackSlice';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ButtonAdd} from '../../buttons/ButtonAdd';
import {SubmittedFeedbackDialog} from './SubmittedFeedbackDialog';
import {ThumbUp} from '@mui/icons-material';
import {MenuItemRow} from '../../../scenes/map-scene/left-panel-menu/item/MenuItemRow';
import {SecondaryLightHover} from '../../../../App';

export interface FeedbackDialogProps {
  open?: boolean;
}

export const FeedbackDialog = ({open}: FeedbackDialogProps): JSX.Element => {
  const {t} = useTranslation();
  const [aboutAppDialog, setAboutAppDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {feedback, feedbackDialogOpen} = useAppSelector(feedbackSelector);

  const handleFeedbackSubmit = () => {
    dispatch(setFeedbackDialogOpen(true));
    dispatch(setFeedbackChecked(true));
    dispatch(sendFeedback());
    setAboutAppDialog(false);
    setTimeout(() => {
      dispatch(setFeedbackDialogOpen(false));
    }, 4000);
  };

  const setEmoticonRating = (emoticonId: number) => {
    dispatch(setFeedbackEmoticon(emoticonId));
  };

  return (
    <>
      <MenuItemRow
        name={t('feedback.Rate us')}
        onClick={() => setAboutAppDialog(true)}
        open={open}
        icon={<ThumbUp />}
      />
      <BasicDialog
        open={aboutAppDialog}
        onCloseDialog={() => setAboutAppDialog(false)}>
        <>
          <Box className={classes.dialogContainer}>
            <Typography className={classes.name}>
              {t('feedback.How is your experience with the app?')}
            </Typography>
            <FeedbackEmoticonSelection
              setEmoticonRating={setEmoticonRating}
              rating={feedback?.rating}
            />
            <Typography className={classes.name}>
              {t('feedback.What would you like to improve')}
            </Typography>
            <CustomInput
              multiline
              value={feedback?.text}
              placeholder={t('feedback.Type your ideas here')}
              className={classes.description}
              onChange={(e) => dispatch(setFeedbackText(e.target.value))}
            />
          </Box>
          <ButtonAdd
            name={t('feedback.Submit')}
            icon={<></>}
            onClick={handleFeedbackSubmit}
          />
        </>
      </BasicDialog>
      <SubmittedFeedbackDialog
        showDialog={!!feedbackDialogOpen}
        onDialogDismiss={() => dispatch(setFeedbackDialogOpen(false))}
      />
    </>
  );
};
// export const CustomInput = withStyles((theme: Theme) =>
//   createStyles({
//     input: {
//       borderRadius: 10,
//       position: 'relative',
//       backgroundColor: theme.palette.secondary.light,
//       fontSize: 18,
//       padding: '10px 12px',
//       boxShadow: theme.shadows[2],
//       minHeight: 100,
//     },
//   })
// )(InputBase);

export const CustomInput = styled(
  InputBase,
  {}
)(({theme}) => ({
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    fontSize: 18,
    padding: '10px 12px',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.shadows[2],
    '&:hover': {
      background: SecondaryLightHover,
    },
  },
}));

const useStyles = makeStyles(() => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
    padding: 10,
  },
  description: {
    width: '100%',
    marginBottom: 30,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 15,
    marginBottom: 4,
  },
  logoInfo: {
    width: 50,
    height: 50,
  },
}));
