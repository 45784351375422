import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';
import {Poi360} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi';
import {Poi360Api} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

export interface Poi360ListError {
  message: string;
}

interface Poi360State {
  poi360List: Poi360[];
  poi360: Poi360 | null;
  loading: boolean;
  error: Poi360ListError;
}

const initialState: Poi360State = {
  poi360List: [],
  loading: false,
  poi360: null,
  error: {message: 'An Error occurred'},
};

export const poi360Slice = createSlice({
  name: 'poi360',
  initialState,
  reducers: {
    setPoi360List: (state, {payload}: PayloadAction<Poi360[]>) => {
      state.poi360List = payload;
    },
    setPoi360: (state, {payload}: PayloadAction<Poi360 | null>) => {
      state.poi360 = payload;
    },
    setPoi360Loading: (state, {payload}: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPoi360ListFailed: (state, {payload}: PayloadAction<Poi360ListError>) => {
      state.error = payload;
    },
    addToPoi360List: (state, {payload}: PayloadAction<Poi360>) => {
      state.poi360List = state.poi360List
        ? [...state.poi360List, payload]
        : [payload];
    },
    updatePoi360InList: (state, {payload}: PayloadAction<Poi360>) => {
      const poi360List = state.poi360List.map((poi360) =>
        poi360.id === payload.id ? payload : poi360
      );
      state.poi360List = [...poi360List];
    },
    removeFrom360List: (state, {payload}: PayloadAction<string>) => {
      const poi360List = state.poi360List.filter(
        (poi360) => poi360.id !== payload
      );
      state.poi360List = [...poi360List];
    },
  },
});

export const getPoi360 =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPoi360Loading(true));
      const groupDetail = await new Poi360Api(
        undefined,
        config.cmsRestUrl
      ).poi360IdGet(id);
      dispatch(setPoi360(groupDetail.data));
    } catch (error) {
      dispatch(setPoi360ListFailed({message: error as string}));
    } finally {
      dispatch(setPoi360Loading(false));
    }
  };

export const {setPoi360, setPoi360ListFailed, setPoi360Loading} =
  poi360Slice.actions;

export const poi360Selector = (state: RootState): typeof state.poi360 =>
  state.poi360;

export default poi360Slice.reducer;
