import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

export interface FeedbackEmoticonProps {
  active?: boolean;
  iconSrc: string;
  onPress?: () => void;
}

export const FeedbackEmoticon = ({
  active,
  onPress,
  iconSrc,
}: FeedbackEmoticonProps): JSX.Element => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      width: 50,
      height: 50,
      borderRadius: 100,
      backgroundColor: active ? '#c5d9ff' : theme.palette.secondary.main,
      cursor: 'pointer',
    },
  }));
  const classes = useStyles();

  return (
    <img
      alt={iconSrc}
      src={iconSrc}
      className={classes.icon}
      onClick={onPress}
    />
  );
};
