import React, {useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../HeaderMenuItem';
import {useTranslation} from 'react-i18next';
import {
  clearObjectPreview,
  selectDrawer,
} from '../../../../../../redux/reducers/DrawerSlice';
import {StylesMenu} from '../StylesMenu';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {
  getBaseNewsList,
  getNewsForMobilePreview,
  newsActions,
  newsSelector,
} from '../../../../../../redux/reducers/data-reducers/NewsSlice';
import {PanelMenuList} from '../PanelMenuList';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {useHistory, useParams} from 'react-router-dom';
import {SearchInput, ListLoadingIndicator} from '@skczu/czu-react-components';
import {ListItemRow, useInfiniteListSearch} from '@skczu/czu-frontend-library';

export const ListOfNewsTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {loadingDetail, loadingList, baseObjList, baseObjSearch, hasMore} =
    useAppSelector(newsSelector);
  const {objectPreview, objectPreviewView} = useAppSelector(selectDrawer);
  const {newsId} = useParams<{newsId: string}>();
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    newsActions,
    dispatch,
    baseObjSearch,
    getBaseNewsList
  );

  useEffect(() => {
    newsId && dispatch(getNewsForMobilePreview(newsId));
    !newsId && dispatch(clearObjectPreview());
  }, [dispatch, newsId]);

  const handleItemClick = (id: string) => {
    history.push(`/map/news/${id}`);
  };

  const getNewsListItems = useMemo((): ListItemRow[] => {
    t('');
    return baseObjList
      ? baseObjList.map((news) => {
          return {
            id: news.id,
            itemRowName: CustomUtils.getContentByLanguage(
              news.name,
              news.nameEn
            ),
            data: news,
            dataType: 'news',
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === news.id
                ? true
                : undefined
              : undefined,
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    objectPreview.objectPreviewData?.id,
    objectPreviewView.openObjectPreview,
    t,
  ]);

  return (
    <Box className={menuClasses.menu}>
      <HeaderMenuItem name={t('leftMenu.news')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          placeholder={t('leftMenu.search')}
          name={'route-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <ListLoadingIndicator showLoading={loadingDetail} />
      <PanelMenuList
        listItems={getNewsListItems}
        handleItemClick={handleItemClick}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
    </Box>
  );
};
