import React, {useRef} from 'react';
import {
  Box,
  IconButton,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Room} from '@mui/icons-material';
import {theme} from '../../../../App';
import {CustomUtils} from '../../../shared/utils/CustomUtils';
import {Hotspot} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      paddingLeft: 10,
      color: '#ffffff',
    },
    poiImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#73be46',
      borderRadius: 100,
      padding: 8,
    },
    dialogMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 30px 5px 5px',
    },
    nameLanguage: {},
    menuItem: {
      justifyContent: 'center',
      marginLeft: 5,
    },
    poiName: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      borderRadius: 10,
      color: '#fff',
      textAlign: 'center',
      maxWidth: 160,
      minWidth: 100,
      padding: '5px 10px',
      marginLeft: -20,
      marginTop: -5,
    },
  })
);

export interface PoiHotspotProps {
  hotspot: Hotspot;
  onGoToPoi: (hotspot: Hotspot) => void;
}

export const PoiHotspot = ({
  hotspot,
  onGoToPoi,
}: PoiHotspotProps): JSX.Element => {
  const classes = useStyles();
  const poiRef = useRef(null);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box>
          <IconButton
            onClick={() => hotspot.poi360 && onGoToPoi(hotspot)}
            ref={poiRef}
            size="large">
            <Box className={classes.poiImage}>
              <Room fontSize={'large'} color={'secondary'} />
            </Box>
          </IconButton>
          {hotspot.showName && (
            <Box className={classes.poiName}>
              {CustomUtils.getContentByLanguage(hotspot.name, hotspot.nameEn)}
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
