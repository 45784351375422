import {AppThunk, RootState} from '../../../store';
import {
  createGenericSlice,
  GenericState,
  getBaseObjList,
  getObj,
} from '@skczu/czu-frontend-library';
import {setActiveObjectPreview} from '../DrawerSlice';
import config from '../../../config';
import {
  BaseNewsWeb,
  News,
  NewsApi,
  WebNewsFilterResponse,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';

const initialState: GenericState<BaseNewsWeb, News> = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const newsSlice = createGenericSlice({
  name: 'news',
  initialState,
})({});

export const getBaseNewsList =
  (newList: boolean, keyword?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      getBaseObjList<BaseNewsWeb, WebNewsFilterResponse>(
        getState().newsList.baseObjSearch,
        getState().newsList.baseObjList,
        () =>
          new NewsApi(undefined, config.cmsRestUrl).newsWebFilterGet(
            keyword,
            getState().newsList.baseObjSearch.limit,
            getState().newsList.baseObjSearch.offset
          ),
        (loading) => dispatch(newsActions.setObjLoadingList(loading)),
        (fail) => dispatch(newsActions.setObjFailed({message: fail})),
        (offset) => dispatch(newsActions.setOffset(offset)),
        (newDataList) =>
          newList
            ? dispatch(
                newsActions.setBaseObjList(newDataList ? newDataList : [])
              )
            : dispatch(newsActions.addToBaseObjList(newDataList)),
        keyword,
        (hasMore) => dispatch(newsActions.hasMore(hasMore))
      )
    );
  };

export const getNews =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<News>(
        id,
        (newsId) =>
          new NewsApi(undefined, config.cmsRestUrl).newsWebIdGet(
            newsId as string
          ),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(newsActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(newsActions.setObj(obj));
        }
      )
    );
  };

export const getNewsForMobilePreview =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      getObj<News>(
        id,
        () => new NewsApi(undefined, config.cmsRestUrl).newsWebIdGet(id),
        (loading) => dispatch(newsActions.setObjLoadingDetail(loading)),
        (fail) => dispatch(newsActions.setObjFailed({message: fail})),
        (obj) => {
          dispatch(
            setActiveObjectPreview({
              objectPreview: {
                objectMobileScene: 'news',
                objectPreviewData: obj,
              },
              objectPreviewView: {
                openObjectPreview: true,
              },
            })
          );
        }
      )
    );
  };

export const newsActions = newsSlice.actions;

export const newsSelector = (state: RootState): typeof state.newsList =>
  state.newsList;

export default newsSlice.reducer;
