import makeStyles from '@mui/styles/makeStyles';

export const StylesMenu = makeStyles(() => ({
  menuSearch: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  menu: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  disableSelection: {
    cursor: 'pointer',
    textAlign: 'center',
    margin: 10,
  },
  categoryList: {
    margin: '5px 35px 10px 28px',
  },
}));
