import React from 'react';
import {Box, Typography} from '@mui/material';
import ListItem from '@mui/material/ListItem';
import {StylesRow} from './StylesRow';
import {ListItemRow} from '@skczu/czu-frontend-library';
import {BaseNewsWeb} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/news';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {EventFilterRefObject} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/event';
import moment from 'moment';

const styles = {
  newsPanel: {
    flex: '1 1 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    minHeight: 110,
  },
  newsPanelText: {
    paddingTop: '4px',
    paddingBottom: '26px',
    paddingLeft: '10px',
  },
  newsCreateText: {
    position: 'absolute',
    bottom: 4,
    color: 'grey',
    fontSize: 12,
  },
  rightNewsCreateText: {
    right: 120,
  },
  image: {
    minWidth: 100,
    width: 100,
    height: 100,
    marginTop: '2px',
    marginBottom: '2px',
    objectFit: 'cover',
  },
};

export const instanceOfBaseNewsWeb = (
  object: BaseNewsWeb | EventFilterRefObject
): object is BaseNewsWeb => {
  return 'miniBody' in object;
};

export interface ImageListItemProps {
  listItem: ListItemRow;
  baseObj: BaseNewsWeb | EventFilterRefObject;
  handleItemClick?: (id: string) => void;
}

export const ImageListItem = ({
  listItem,
  baseObj,
  handleItemClick,
}: ImageListItemProps): JSX.Element => {
  const getEventDateString = (event: EventFilterRefObject): string => {
    const start =
      event.startDate && moment(event.startDate).isValid()
        ? moment(event.startDate).format('DD.MM.YYYY HH:mm')
        : '';
    const end =
      event.endDate && moment(event.endDate).isValid()
        ? moment(event.endDate).format('DD.MM.YYYY HH:mm')
        : '';
    return `${start}${start && end ? ' - ' : ''}${end ? end : ''}`;
  };
  return (
    <ListItem
      sx={{
        ...(listItem.selectedItem
          ? {...StylesRow.listItem, ...StylesRow.listItemSelected}
          : StylesRow.listItem),
      }}
      key={listItem.id}
      onClick={() => handleItemClick && handleItemClick(listItem.id)}>
      <Box sx={styles.newsPanel}>
        <Box sx={styles.newsPanelText}>
          <Typography variant={'subtitle1'} sx={StylesRow.title}>
            {listItem.itemRowName}
          </Typography>
          <Typography variant={'body2'}>
            {instanceOfBaseNewsWeb(baseObj) &&
              CustomUtils.getContentByLanguage(
                baseObj.miniBody,
                baseObj.miniBodyEn
              )
                ?.substring(0, 60)
                .trim()}
            {instanceOfBaseNewsWeb(baseObj) &&
            CustomUtils.getContentByLanguage(
              baseObj.miniBody,
              baseObj.miniBodyEn
            )?.length > 59
              ? '...'
              : ''}
          </Typography>
          {instanceOfBaseNewsWeb(baseObj) ? (
            <Typography sx={styles.newsCreateText}>
              {moment(baseObj.lastUpdateDate).format('DD.MM.YYYY HH:mm')}
            </Typography>
          ) : (
            <Typography
              variant={'body2'}
              sx={{
                ...styles.newsCreateText,

                ...(baseObj.endDate &&
                  !baseObj.startDate &&
                  styles.rightNewsCreateText),
              }}>
              {getEventDateString(baseObj)}
            </Typography>
          )}
        </Box>
      </Box>
      {baseObj.image?.thumbnailUrl && (
        <Box
          component={'img'}
          alt={listItem.data.name}
          src={baseObj.image?.thumbnailUrl}
          sx={styles.image}
        />
      )}
      {!baseObj.image?.mobileUrl && (
        <Box
          component={'img'}
          alt={listItem.data.name}
          src={'/assets/image/news.jpg'}
          sx={styles.image}
        />
      )}
    </ListItem>
  );
};
