import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {ImagePreview} from '@skczu/czu-frontend-library';

export interface ImagePreviewState {
  imagePreview: boolean;
  preview?: ImagePreview;
}

const initialState: ImagePreviewState = {
  imagePreview: false,
};

export const imagePreviewSlice = createSlice({
  name: 'imagePreview',
  initialState,
  reducers: {
    showImagePreview: (state, {payload}: PayloadAction<ImagePreview>) => {
      state.preview = payload;
      state.imagePreview = true;
    },
    changeSelectedImage: (state, {payload}: PayloadAction<number>) => {
      const newSelectedImage = state.preview?.images?.find(
        (image) => image.index === payload
      );
      if (newSelectedImage && state.preview?.selectedImage) {
        state.preview.selectedImage = newSelectedImage;
      }
    },
    closeImagePreview: (state) => {
      state.preview = undefined;
      state.imagePreview = false;
    },
  },
});

export const {showImagePreview, closeImagePreview, changeSelectedImage} =
  imagePreviewSlice.actions;

export const imagePreviewSelector = (
  state: RootState
): typeof state.imagePreview => state.imagePreview;

export default imagePreviewSlice.reducer;
