import React, {useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../HeaderMenuItem';
import {useTranslation} from 'react-i18next';
import {
  clearObjectPreview,
  selectDrawer,
} from '../../../../../../redux/reducers/DrawerSlice';
import {StylesMenu} from '../StylesMenu';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {PanelMenuList} from '../PanelMenuList';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {useHistory, useParams} from 'react-router-dom';
import {SearchInput, ListLoadingIndicator} from '@skczu/czu-react-components';
import {ListItemRow, useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {
  getBaseGroupsList,
  getGroupForMobilePreview,
  groupActions,
  groupsSelector,
} from '../../../../../../redux/reducers/data-reducers/GroupsSlice';

export const ListOfEventsTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {loadingDetail, loadingList, baseObjList, baseObjSearch, hasMore} =
    useAppSelector(groupsSelector);
  const {objectPreview, objectPreviewView} = useAppSelector(selectDrawer);
  const {eventId} = useParams<{eventId: string}>();
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    groupActions,
    dispatch,
    baseObjSearch,
    getBaseGroupsList
  );

  useEffect(() => {
    eventId && dispatch(getGroupForMobilePreview(eventId));
    !eventId && dispatch(clearObjectPreview());
  }, [dispatch, eventId]);

  const handleItemClick = (id: string) => {
    history.push(`/map/events/${id}`);
  };

  const getGroupListItems = useMemo((): ListItemRow[] => {
    t('');
    return baseObjList
      ? baseObjList.map((event) => {
          return {
            id: event.id,
            itemRowName: CustomUtils.getContentByLanguage(
              event.name,
              event.nameEn
            ),
            data: event,
            dataType: 'event',
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === event.id
                ? true
                : undefined
              : undefined,
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    objectPreview.objectPreviewData?.id,
    objectPreviewView.openObjectPreview,
    t,
  ]);

  return (
    <Box className={menuClasses.menu}>
      <HeaderMenuItem name={t('leftMenu.events')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          placeholder={t('leftMenu.search')}
          name={'route-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <ListLoadingIndicator showLoading={loadingDetail} />
      <PanelMenuList
        listItems={getGroupListItems}
        handleItemClick={handleItemClick}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
    </Box>
  );
};
