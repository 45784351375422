import {AppThunk, RootState} from '../../../store';
import {createGenericSlice, GenericState} from '@skczu/czu-frontend-library';
import config from '../../../config';
import {
  Configuration,
  Object3d,
  Object3DApi,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/object3d';
import {setLoading} from '../LoadingSlice';
export interface TileUrl {
  tileUrl: string;
}
const initialState: GenericState<TileUrl, Object3d> = {
  baseObjList: [],
  hasMore: true,
  objList: [],
  obj: null,
  openObjDialog: false,
  addNewObj: false,
  loadingList: false,
  loadingDetail: false,
  baseObjSearch: {
    limit: 10,
    offset: 0,
  },
  error: {message: 'An Error occurred'},
};

const object3dSlice = createGenericSlice({
  name: 'object3dList',
  initialState,
})({});

export const getTileset3dUrlList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await new Object3DApi(
      new Configuration(),
      config.cmsRestUrl
    ).object3DTilesetsFilterGet();
    if (response?.data) {
      dispatch(
        object3dActions.setBaseObjList(
          response.data.map((url) => ({tileUrl: url}))
        )
      );
    }
  } catch (error) {
    // dispatch(showErrorMessage());
  } finally {
    dispatch(setLoading(false));
  }
};

export const object3dActions = object3dSlice.actions;

export const object3dSelector = (state: RootState): typeof state.object3dList =>
  state.object3dList;

export default object3dSlice.reducer;
