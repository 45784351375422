import React from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {TextList} from './AppImageInfo';
export const SignpostTextColorGray = '#474747';
const styles = {
  appInfoImageContainer: {
    maxWidth: isMobile ? '100vw' : '40vw',
    minWidth: isMobile ? '100vw' : '40vw',
    alignSelf: 'start',
    marginTop: isMobile ? '4vw' : '5vw',
  },
  appImageSubText: {
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: isMobile ? '5.5vw' : '1.4vw',
    lineHeight: isMobile ? '5.8vw' : '1.6vw',
    marginBottom: isMobile ? '4vw' : '1vw',
  },
  appImageText: {
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: isMobile ? '4vw' : '1vw',
    marginLeft: isMobile ? '5vw' : '1vw',
    marginRight: isMobile ? '5vw' : '1vw',
    marginTop: isMobile ? '2vw' : '1vw',
    color: SignpostTextColorGray,
  },
  appImageName: {
    fontWeight: 'bold',
    fontSize: isMobile ? '5.5vw' : '1.4vw',
    lineHeight: isMobile ? '5.5vw' : '1.4vw',
    textAlign: 'center',
    justifyContent: 'center',
    color: SignpostTextColorGray,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  textListContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isMobile ? '5vw' : '2vw',
    marginRight: isMobile ? '5vw' : '0vw',
    marginTop: isMobile ? '2vw' : '1vw',
  },
  textListContainerDescription: {
    textAlign: isMobile ? 'center' : 'left',
    justifyContent: 'center',
    fontSize: isMobile ? '4vw' : '1vw',
    color: SignpostTextColorGray,
  },
  webTextListDot: {
    borderRadius: 100,
    backgroundColor: SignpostTextColorGray,
    width: '0.3vw',
    height: '0.3vw',
    marginRight: '0.4vw',
  },
  webTextListContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textListContainerText: {
    textAlign: isMobile ? 'center' : 'left',
    marginTop: isMobile ? '4vw' : '0vw',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: isMobile ? '4vw' : '1vw',
    color: SignpostTextColorGray,
  },
};
export interface AppInfoProps {
  text: string;
  description?: string;
  textList?: TextList[];
  name: string;
  subtitle?: string;
}
export const AppInfo = ({
  name,
  text,
  textList,
  description,
  subtitle,
}: AppInfoProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Box sx={styles.appInfoImageContainer}>
      <Typography sx={styles.appImageName}>
        {t(`signPost.${name}`).toUpperCase()}
      </Typography>
      {subtitle ? (
        <Box marginTop={isMobile ? '4vw' : '1vw'}>
          <Typography sx={styles.appImageSubText}>
            {t(`signPost.${subtitle}`)}
          </Typography>
        </Box>
      ) : null}
      {text ? (
        <Typography sx={styles.appImageText}>
          {t(`signPost.${text}`)}
        </Typography>
      ) : null}
      {description ? (
        <Box marginTop={isMobile ? '4vw' : '1vw'}>
          <Typography sx={styles.appImageText}>
            {t(`signPost.${description}`)}
          </Typography>
        </Box>
      ) : null}
      {textList && (
        <Box sx={styles.textListContainer}>
          {textList.map((txt, index) => (
            <Box key={'textList_' + index}>
              {isMobile ? (
                <Typography sx={styles.textListContainerText}>
                  {t(`signPost.${txt.title}`)}
                </Typography>
              ) : (
                <>
                  <Box sx={styles.webTextListContainer}>
                    <Box sx={styles.webTextListDot}></Box>
                    <Typography sx={styles.textListContainerText}>
                      {t(`signPost.${txt.title}`)}
                    </Typography>
                  </Box>
                </>
              )}
              {txt.description ? (
                <Typography sx={styles.textListContainerDescription}>
                  {t(`signPost.${txt.description}`)}
                </Typography>
              ) : null}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
