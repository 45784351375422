import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import mapReducer from './redux/reducers/MapSlice';
import loadingReducer from './redux/reducers/LoadingSlice';
import drawerReducer from './redux/reducers/DrawerSlice';
import poiReducer from './redux/reducers/data-reducers/PoiSlice';
import routeReducer from './redux/reducers/data-reducers/RouteSlice';
import newsReducer from './redux/reducers/data-reducers/NewsSlice';
import poiCategoriesReducer from './redux/reducers/data-reducers/PoiCategoriesSlice';
import object3dListReducer from './redux/reducers/data-reducers/Object3dSlice';
import groupsReducer from './redux/reducers/data-reducers/GroupsSlice';
import poi360Reducer from './redux/reducers/data-reducers/Poi360Slice';
import foodMenuReducer from './redux/reducers/data-reducers/FoodMenuSlice';
import imagePreviewReducer from './redux/reducers/ImagePreview';
import feedbackReducer from './redux/reducers/FeedbackSlice';
import wishlistReducer from './redux/reducers/data-reducers/WishlistSlice';

export const store = configureStore({
  reducer: {
    map: mapReducer,
    drawer: drawerReducer,
    loading: loadingReducer,
    poiList: poiReducer,
    routeList: routeReducer,
    poiCategories: poiCategoriesReducer,
    newsList: newsReducer,
    object3dList: object3dListReducer,
    groupsList: groupsReducer,
    poi360: poi360Reducer,
    imagePreview: imagePreviewReducer,
    foodMenu: foodMenuReducer,
    feedback: feedbackReducer,
    wishlist: wishlistReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
