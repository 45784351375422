import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import {useHistory} from 'react-router-dom';
import {setObjectPreviewView} from '../../../../../redux/reducers/DrawerSlice';
import {useAppDispatch} from '../../../../../hooks/hooks';
import {ButtonBack} from '@skczu/czu-react-components';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  divider: {
    height: 3,
  },
}));

export interface HeaderMenuItemProps {
  name: string;
  onBackClick?: () => void;
}

export const HeaderMenuItem = ({
  name,
  onBackClick,
}: HeaderMenuItemProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleNavigation = useCallback(
    (newPath: string) => history.push(`${newPath}`),
    [history]
  );

  const handleGoBack = useCallback(() => {
    if (onBackClick) {
      onBackClick();
    }
    dispatch(
      setObjectPreviewView({
        openObjectPreview: false,
      })
    );
    handleNavigation(
      history.location.pathname.substring(
        0,
        history.location.pathname.lastIndexOf('/')
      )
    );
  }, [dispatch, handleNavigation, history.location.pathname, onBackClick]);

  return (
    <Box>
      <Box className={classes.toolbar}>
        <Box width={'100%'}>
          <h2>{name}</h2>
        </Box>
        <Box>
          <ButtonBack onClick={handleGoBack} />
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};
