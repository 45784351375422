import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import {Pannellum} from 'pannellum-react';
import ReactDOM from 'react-dom';
import makeStyles from '@mui/styles/makeStyles';
import {PoiHotspot} from './hotspot/PoiHotspot';
import {CustomUtils} from '../../shared/utils/CustomUtils';
import {useTranslation} from 'react-i18next';
import {RotateLeft} from '@mui/icons-material';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

const useStyles = makeStyles((theme) => ({
  bottomNameContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    width: '30%',
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  poi360Name: {
    fontSize: 22,
    padding: 10,
    color: theme.palette.secondary.main,
  },
  buttonContainer: {
    position: 'absolute',
    right: '2%',
    top: '2%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttonMap: {
    textAlign: 'center',
    cursor: 'pointer',
    padding: 6,
    borderRadius: 5,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.secondary.main,
  },
  buttonBack: {
    cursor: 'pointer',
    borderRadius: 5,
    padding: 6,
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.secondary.main,
  },
  buttonIcon: {
    marginRight: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: theme.palette.secondary.contrastText,
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

export interface PanoramaComponentProps {
  globalPoi360: boolean;
  openDialog: boolean;
  onGoToMap: () => void;
  onGoToGlobalPoi360: () => void;
  poi360: Poi360 | null;
  onGoToPoi: (hotspot: Hotspot) => void;
  fromPoi360?: Poi360;
}

export const PanoramaComponent = ({
  globalPoi360,
  openDialog,
  onGoToMap,
  onGoToGlobalPoi360,
  poi360,
  fromPoi360,
  onGoToPoi,
}: PanoramaComponentProps): JSX.Element => {
  const {t} = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const panImage: any = React.useRef(null);

  const onSetCurrentHotspots = () => {
    poi360?.hotspot?.map((poiHotspot, index) => {
      if (poiHotspot.yaw && poiHotspot.pitch) {
        const poiId = `${poiHotspot.poi360}_#_#${index}`;
        panImage?.current?.getViewer().addHotSpot({
          id: poiId,
          pitch: poiHotspot.pitch,
          yaw: poiHotspot.yaw,
          cssClass: 'custom-hotspot',
          createTooltipArgs: poiId,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          createTooltipFunc: (hotSpotDiv: any) => {
            hotspotIconT(hotSpotDiv, poiId, poiHotspot);
          },
        });
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hotspotIconT = (hotSpotDiv: any, id: string, hotspot: Hotspot) => {
    ReactDOM.render(
      <PoiHotspot
        key={id}
        hotspot={hotspot}
        onGoToPoi={(poi360Id) => onGoToPoi(poi360Id)}
      />,
      hotSpotDiv
    );
  };

  const getYaw = useCallback(
    () =>
      fromPoi360?.id &&
      poi360?.hotspot?.find((hotspot) => hotspot.poi360 === fromPoi360.id)?.yaw,
    [fromPoi360?.id, poi360?.hotspot]
  );

  return (
    <Box>
      {poi360?.image?.url && openDialog && (
        <Pannellum
          width="100%"
          height="100vh"
          image={poi360?.image.url}
          pitch={globalPoi360 ? 310 : 0}
          yaw={globalPoi360 ? 25 : getYaw() ? Number(getYaw()) + 180 : -90}
          hfov={120}
          maxHfov={120}
          minHfov={20}
          doubleClickZoom={false}
          maxPitch={globalPoi360 ? 360 : 90}
          minPitch={globalPoi360 ? 180 : -90}
          showFullscreenCtrl={false}
          autoLoad
          showZoomCtrl={false}
          onLoad={() => onSetCurrentHotspots()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={panImage}
        />
      )}
      <Box className={classes.buttonContainer}>
        <Box className={classes.buttonMap} onClick={onGoToMap}>
          <Box className={classes.buttonText}>{t('poi360.Back to map')}</Box>
        </Box>
        {!globalPoi360 && (
          <Box className={classes.buttonBack} onClick={onGoToGlobalPoi360}>
            <Box className={classes.buttonIcon}>
              <RotateLeft />
            </Box>
            <Box className={classes.buttonText}>
              {t('poi360.Campus overview')}
            </Box>
          </Box>
        )}
      </Box>
      {poi360?.showName && (
        <Box className={classes.bottomNameContainer}>
          <Box className={classes.poi360Name}>
            {CustomUtils.getContentByLanguage(poi360?.name, poi360?.nameEn)}
          </Box>
        </Box>
      )}
    </Box>
  );
};
