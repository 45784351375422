import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {PanoramaComponent} from './PanoramaComponent';
import {
  getPoi360,
  setPoi360,
  poi360Selector,
} from '../../../redux/reducers/data-reducers/Poi360Slice';
import {useHistory, useParams} from 'react-router-dom';
import {clearObjectPreview} from '../../../redux/reducers/DrawerSlice';
import config from '../../../config';
import {MapLoadingIndicator} from '@skczu/czu-react-components';
import {
  Hotspot,
  Poi360,
} from '@skczu/czu-frontend-library/build/apis/cms-service/generated/poi360';

export const PanoramaPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {poi360, loading} = useAppSelector(poi360Selector);
  const history = useHistory();
  const [fromPoi360, setFromPoi360] = useState<Poi360>();
  const {poi360Id} = useParams<{poi360Id: string}>();
  const navigateTo = useCallback((path) => history.push(path), [history]);

  useEffect(() => {
    poi360Id && dispatch(getPoi360(poi360Id));
    !poi360Id &&
      config.globalPoi360ID &&
      dispatch(getPoi360(config.globalPoi360ID));
  }, [dispatch, poi360Id]);

  const handleGoToPoi360 = useCallback(
    (id: string) => {
      history.push(`/map/panorama/${id}`);
    },
    [history]
  );

  const onGoToMap = useCallback(() => {
    dispatch(clearObjectPreview());
    dispatch(setPoi360(null));
    navigateTo('/map');
  }, [dispatch, navigateTo]);

  const handleGoToPoi = useCallback(
    (hotspot: Hotspot) => {
      if (poi360) {
        poi360.id !== hotspot.poi360 &&
          hotspot.poi360 &&
          handleGoToPoi360(hotspot.poi360);
      } else {
        hotspot.poi360 && handleGoToPoi360(hotspot.poi360);
      }
      poi360 && setFromPoi360(poi360);
    },
    [handleGoToPoi360, poi360]
  );

  const renderPanorama = useMemo(() => {
    return (
      poi360 && (
        <PanoramaComponent
          globalPoi360={!poi360Id}
          openDialog={true}
          onGoToMap={onGoToMap}
          poi360={poi360}
          fromPoi360={fromPoi360}
          onGoToPoi={(hotspot) => handleGoToPoi(hotspot)}
          onGoToGlobalPoi360={() => navigateTo('/map/panorama')}
        />
      )
    );
  }, [fromPoi360, handleGoToPoi, navigateTo, onGoToMap, poi360, poi360Id]);

  return (
    <Box>
      <MapLoadingIndicator showLoading={loading} />
      {renderPanorama}
    </Box>
  );
};
