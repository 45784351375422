import React from 'react';
import {Box, Icon, useTheme} from '@mui/material';

export interface MapButtonProps {
  imageSource?: string;
  buttonName: string;
  selected?: boolean;
  onClick?: () => void;
}

export const MapButton = ({
  imageSource,
  onClick,
  selected,
  buttonName,
}: MapButtonProps): JSX.Element => {
  const theme = useTheme();
  const styles = {
    buttonContainer: {
      textAlign: 'center',
      marginRight: '8px',
      cursor: 'pointer',
      borderRadius: '5px',
      height: 64,
      width: 84,
      boxShadow: theme.shadows[2],
      backgroundColor: selected
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    },
    icon: {
      marginTop: '-3px',
    },
    text: {
      color: selected
        ? theme.palette.secondary.main
        : theme.palette.secondary.contrastText,
      marginTop: '-12px',
      fontSize: 12,
      fontWeight: 'bold',
    },
  };

  return (
    <Box sx={styles.buttonContainer} onClick={onClick}>
      {imageSource && (
        <Box sx={styles.icon}>
          <Icon sx={{height: 55, width: 55}}>
            <Box
              component={'img'}
              src={imageSource}
              sx={{height: 55, width: 55}}
              alt={buttonName}
            />
          </Icon>
        </Box>
      )}
      <Box sx={styles.text}>{buttonName}</Box>
    </Box>
  );
};
