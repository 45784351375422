import React, {useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {HeaderMenuItem} from '../HeaderMenuItem';
import {
  clearObjectPreview,
  selectDrawer,
} from '../../../../../../redux/reducers/DrawerSlice';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import {
  routeSelector,
  getBaseRouteList,
  routeActions,
  getRouteForMobilePreview,
} from '../../../../../../redux/reducers/data-reducers/RouteSlice';
import {StylesMenu} from '../StylesMenu';
import {useTranslation} from 'react-i18next';
import {PanelMenuList} from '../PanelMenuList';
import {CustomUtils} from '../../../../../shared/utils/CustomUtils';
import {useHistory, useParams} from 'react-router-dom';
import {ListItemRow, useInfiniteListSearch} from '@skczu/czu-frontend-library';
import {SearchInput, ListLoadingIndicator} from '@skczu/czu-react-components';

export const ListOfRoutesTab = (): JSX.Element => {
  const {t} = useTranslation();
  const menuClasses = StylesMenu();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {loadingDetail, loadingList, baseObjList, baseObjSearch, hasMore} =
    useAppSelector(routeSelector);
  const {objectPreview, objectPreviewView} = useAppSelector(selectDrawer);
  const {routeId} = useParams<{routeId: string}>();
  const {onChangeValue, handleLoadMore, handleSearch} = useInfiniteListSearch(
    routeActions,
    dispatch,
    baseObjSearch,
    getBaseRouteList
  );

  useEffect(() => {
    routeId && dispatch(getRouteForMobilePreview(routeId));
    !routeId && dispatch(clearObjectPreview());
  }, [dispatch, routeId]);

  const handleItemClick = (id: string) => {
    history.push(`/map/routes/${id}`);
  };

  const getRouteListItems = useMemo((): ListItemRow[] => {
    t('');
    return baseObjList
      ? baseObjList.map((route) => {
          return {
            id: route.id,
            itemRowName: CustomUtils.getContentByLanguage(
              route.name,
              route.nameEn
            ),
            data: route,
            dataType: 'route',
            selectedItem: objectPreviewView.openObjectPreview
              ? objectPreview.objectPreviewData?.id === route.id
                ? true
                : undefined
              : undefined,
          } as ListItemRow;
        })
      : [];
  }, [
    baseObjList,
    objectPreview.objectPreviewData?.id,
    objectPreviewView.openObjectPreview,
    t,
  ]);

  return (
    <Box className={menuClasses.menu}>
      <HeaderMenuItem name={t('leftMenu.routes')} />
      <Box className={menuClasses.menuSearch}>
        <SearchInput
          placeholder={t('leftMenu.search')}
          name={'route-search'}
          value={baseObjSearch.searchQuery}
          onChangeValue={onChangeValue}
          onSearchClick={handleSearch}
        />
      </Box>
      <ListLoadingIndicator showLoading={loadingDetail} />
      <PanelMenuList
        listItems={getRouteListItems}
        handleItemClick={handleItemClick}
        handleLoadMore={handleLoadMore}
        loadingList={loadingList}
        hasMore={hasMore}
      />
    </Box>
  );
};
