import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {
  ObjectPreview,
  MobilePreviewSceneData,
} from '@skczu/czu-frontend-library';

export interface ObjectPreviewView {
  openObjectPreview?: boolean;
}

interface DrawerState<T> {
  objectPreview: ObjectPreview<T>;
  objectPreviewView: ObjectPreviewView;
}

const initialState: DrawerState<MobilePreviewSceneData> = {
  objectPreview: {
    objectMobileScene: null,
  },
  objectPreviewView: {
    openObjectPreview: false,
  },
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setObjectPreviewView: (
      state,
      {payload}: PayloadAction<ObjectPreviewView>
    ) => {
      state.objectPreviewView = payload;
    },
    setObjectToMobileScene: (
      state,
      {payload}: PayloadAction<ObjectPreview<MobilePreviewSceneData>>
    ) => {
      state.objectPreview = payload;
    },
    setActiveObjectPreview: (
      state,
      {payload}: PayloadAction<DrawerState<MobilePreviewSceneData>>
    ) => {
      state.objectPreview = payload.objectPreview;
      state.objectPreviewView = payload.objectPreviewView;
    },
    updateActiveObjectPreviewData: (
      state,
      {payload}: PayloadAction<MobilePreviewSceneData>
    ) => {
      state.objectPreview.objectPreviewData = payload;
    },
    clearObjectPreview: (state) => {
      state.objectPreview.objectMobileScene = null;
      state.objectPreview.objectPreviewData = null;
      state.objectPreviewView.openObjectPreview = false;
    },
  },
});

export const {
  setObjectPreviewView,
  setObjectToMobileScene,
  setActiveObjectPreview,
  clearObjectPreview,
  updateActiveObjectPreviewData,
} = drawerSlice.actions;

export const selectDrawer = (state: RootState): typeof state.drawer =>
  state.drawer;

export default drawerSlice.reducer;
