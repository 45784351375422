import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  Configuration,
  Wishlist,
  WishlistApi,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {AppThunk, RootState} from '../../../store';
import config from '../../../config';

interface WishlistState {
  wishlist?: Wishlist;
}

const initialState: WishlistState = {};

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlist: (state, {payload}: PayloadAction<Wishlist>) => {
      state.wishlist = payload;
    },
  },
});

export const getWishlist = (): AppThunk => async (dispatch) => {
  try {
    const response = await new WishlistApi(
      new Configuration(),
      config?.userDataRestUrl
    ).publicWishlistGet();
    if (response) {
      dispatch(setWishlist(response.data));
    }
  } catch (error) {
    // dispatch(showErrorMessage('Failed delete user from group'));
  }
};

export const {setWishlist} = wishlistSlice.actions;

export const wishlistSelector = (state: RootState): typeof state.wishlist =>
  state.wishlist;

export default wishlistSlice.reducer;
