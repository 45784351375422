import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

interface LoadingState {
  isLoading: boolean;
  isMapLoading: boolean;
  loadingCounter: number;
}

const initialState: LoadingState = {
  isLoading: false,
  isMapLoading: false,
  loadingCounter: 0,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.isLoading = payload;
      state.loadingCounter = payload
        ? state.loadingCounter + 1
        : state.loadingCounter > 0
        ? state.loadingCounter - 1
        : 0;
    },
    setMapLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.isMapLoading = payload;
    },
  },
});

export const {setLoading, setMapLoading} = loadingSlice.actions;

export const loadingSelector = (state: RootState): typeof state.loading =>
  state.loading;

export default loadingSlice.reducer;
