// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GraphHopperRouting from 'graphhopper-js-api-client/src/GraphHopperRouting';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GHInput from 'graphhopper-js-api-client/src/GHInput';
import {GraphhopperRoute} from '@skczu/czu-frontend-library';

export const getGraphhopperRoute = async ({
  routePoints,
  vehicle,
  locale,
  host,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
GraphhopperRoute): Promise<any> => {
  const ghRouting = new GraphHopperRouting({
    vehicle,
    locale,
    geometries: 'geometries',
    elevation: false,
    host: host,
    'ch.disable': true,
  });
  routePoints.forEach((point) => {
    ghRouting.addPoint(new GHInput(point.latitude, point.longitude));
  });
  return ghRouting.doRequest();
};
