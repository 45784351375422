import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  Configuration,
  FeedbackApi,
  FeedbackRequest,
} from '@skczu/czu-frontend-library/build/apis/user-data-service/generated';
import {AppThunk, RootState} from '../../store';
import {setLoading} from './LoadingSlice';
import config from '../../config';

export type FeedbackDeviceInfo = Omit<FeedbackRequest, 'text' | 'rating'>;

export interface FeedbackState {
  feedback?: FeedbackRequest;
  feedbackDialogOpen: boolean;
  checked: boolean;
}
const initialState: FeedbackState = {
  checked: false,
  feedbackDialogOpen: false,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedbackText: (state, {payload}: PayloadAction<string>) => {
      state.feedback = {...state.feedback, text: payload};
    },
    setFeedbackChecked: (state, {payload}: PayloadAction<boolean>) => {
      state.checked = payload;
    },
    setFeedbackDialogOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.feedbackDialogOpen = payload;
    },
    setFeedbackEmoticon: (state, {payload}: PayloadAction<number>) => {
      state.feedback = {
        ...state.feedback,
        rating: state.feedback?.rating === payload ? undefined : payload,
      };
    },
    setFeedbackDeviceInfo: (
      state,
      {payload}: PayloadAction<FeedbackDeviceInfo>
    ) => {
      state.feedback = {
        ...state.feedback,
        ...payload,
      };
    },
  },
});

export const sendFeedback = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const feedback = getState().feedback.feedback;
    await new FeedbackApi(
      new Configuration(),
      config?.userDataRestUrl
    ).publicFeedbackPost(feedback);
  } catch (error) {
    // console.log('error - sendFeedback');
    // console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const {
  setFeedbackText,
  setFeedbackEmoticon,
  setFeedbackChecked,
  setFeedbackDialogOpen,
  setFeedbackDeviceInfo,
} = feedbackSlice.actions;

export const feedbackSelector = (state: RootState): typeof state.feedback =>
  state.feedback;

export default feedbackSlice.reducer;
